import { Injectable, Output, EventEmitter } from "@angular/core";
import { DatabaseService } from "../general/database.service";
import * as CodeMirror from "codemirror";
import { Loop, LoopType } from "./loop";
import { LoopParsingService } from "./loopparsing.service";
import { LoopTokenizer } from "./loopformatting.service";
import { TransformService } from "../general/transform.service";


export class EditLoop{ // Repräsentiert eine Funktion in Bearbeitung
    original: Loop; // Das gespeicherte Original
    currentName: string; // Der aktuelle Name
    currentParam: number; // Aktuelle Anzahl der Parameter
    currentComment: string; // Der aktuelle Kommentar
    currentCode: string; // Der aktuelle Funktionstext
    currentLoop: Loop; // Momentane Ergebnisfunktion
    message: string; // Code des Hilfetexts zum aktuellen Zustand der Definition
    messageParam: object; // Parameter zum Hilfetext
    msgType: string; // Art des Hilfetexts: "info", "warning", "error"
    canSave: boolean; // Kann die Funktion momentan gespeichert werden?
    editID: string; // ID der Bearbeitung
    constructor(orig: Loop, name: string = "", nParam:number=0,code:string="") {
        this.editID = Math.random().toString(36).substring(2);
        this.original = orig ? orig : undefined;
        this.currentName = orig ? orig.name : name;
        this.currentParam = orig ? orig.nParam : nParam;
        this.currentComment = orig ? orig.comment : "";
        this.currentCode = orig ? orig.toIndented() : code;
        this.currentLoop = undefined;
        this.message = " ";
        this.msgType = "info"
        this.canSave = false;
    }
    
    unregTokenizer() {
        CodeMirror.defineMode(this.editID, undefined);
    }
    isBasic(): boolean {
        return this.original ? this.original.isBasic() : false;
    }
    hasChanged(): boolean{
        if (this.original) {
            return !this.currentLoop || this.original.name != this.currentName ||
                this.original.nParam != this.currentParam ||
                this.original.comment != this.currentComment ||
                this.original.toString() != this.currentLoop.toString();
        }
        return this.currentName != "";
    }
    update(basefunctions: Array<Loop>, parser: LoopParsingService, db: DatabaseService): Loop { // Funktionsdefinition wurde verändert
        try {
            this.message = " "; // Message zurücksetzen
            this.currentLoop = undefined;
            CodeMirror.defineMode(this.editID, () => new LoopTokenizer(() => basefunctions));
            if (this.isBasic())
                this.currentLoop = this.original;
            else {
                this.currentLoop = parser.parseLoop(this.currentName, this.currentParam, this.currentComment, this.currentCode, basefunctions); // Definition prüfen
            }
            if (this.currentLoop.isBasic()) { // Parsing ergab eine Basisfunktion
                this.message = "BASEFUNCTION";
                this.messageParam = undefined;
                this.canSave = false; // Basisfunktionen können niemals verändert werden
                this.msgType = this.original && this.original.isBasic() ? "info" : "error"; // Hinweis oder Fehler (wenn Basisfunktion neu definiert werden soll)
            }
            else if (this.currentLoop.type != LoopType.NEW) { // Parsing ergab eine bereits gespeicherte User-Funktion
                this.message = "FUNCEXISTSALREADY";
                this.messageParam = { fname: this.currentLoop.name }
                this.canSave = false; // Kann nicht gespeichert werden
                this.msgType = "error";
            }
            else {
                if (this.original && this.currentLoop.nParam != this.original.nParam && db.usedByLoop(this.original).size) {
                    this.message = "PARAMETERSCHANGED";
                    this.messageParam = { fname: this.currentLoop.name }
                    this.msgType = "error";
                    this.canSave = false;
                    return undefined;
                }
                this.message = "PRFCORRECT";
                this.messageParam = { fname: this.currentLoop.name }
                this.msgType = "info";
                this.canSave = this.hasChanged();
                return this.currentLoop;
            }
        }
        catch (err) {
            this.message = err.msg;
            this.messageParam = err.msgParam;
            this.msgType = "error"
            this.canSave = false;
        }
        return undefined;
    }
    optimize(transform: TransformService) {
        transform.optimizeLoop(this.currentLoop);
        this.currentCode = this.currentLoop.toIndented();
    }
}

@Injectable()
export class LoopEditingService {
    @Output() editing: EventEmitter<number> = new EventEmitter();
    theFunctions: Loop[];
    public currentIndex: number;
    public editFunctions: Array<EditLoop>;
    constructor(private db: DatabaseService, private parser: LoopParsingService, private transform: TransformService) {
        this.theFunctions = db.loops;
        this.editFunctions = [];
        this.currentIndex = -1;
    }
    update(f: EditLoop, optimize: boolean = false) {
        let basefunctions = new Set(this.db.loops);
        let ignored = [];
        if (f.original) {
            for (let d of this.db.usedByLoop(f.original)) {
                basefunctions.delete(d);
                ignored.push(d);
            }
            basefunctions.delete(f.original);
        }
        let resLoop = f.update(Array.from(basefunctions), this.parser, this.db);
        if (resLoop) {
            if (ignored.find(i => i.name == resLoop.name)) {
                f.message = "FUNCEXISTSALREADY";
                f.messageParam = { fname: resLoop.name }
                f.canSave = false; // Kann nicht gespeichert werden
                f.msgType = "error";
            }
            else if (optimize)
                f.optimize(this.transform);
        }
    }
    create(name:string, nParam:number=0,code:string="", optimize:boolean=false) { // Eine neue Funktion wird erzeugt
        this.currentIndex = this.editFunctions.push(new EditLoop(undefined, name, nParam, code)) - 1;
        this.update(this.editFunctions[this.currentIndex], optimize);
    }
    edit(f: Loop) { // Ein Original wird bearbeitet
        this.currentIndex = this.editFunctions.findIndex(ef=>ef.original == f);
        if (this.currentIndex == -1) // Es gibt noch keinen offenen Tab der aktiviert werden könnte
            this.currentIndex = this.editFunctions.push(new EditLoop(f)) - 1;
        this.update(this.editFunctions[this.currentIndex]);
    }
    editIndex(i: number) { // Ein Tab wird aktiviert
        if (i < 0) return;
        this.currentIndex = i;
        this.update(this.editFunctions[this.currentIndex]);
    }
    stopEditSet(fns: Set<Loop>) { // Eine ganze Menge von Original-Funktionen wird nicht mehr bearbeitet
        for (let i = this.editFunctions.length - 1; i >= 0; i--) { // Tabs von hinten nach vorn
            if (fns.has(this.editFunctions[i].original)) { // ein zu schließender Tab
                this.editFunctions[i].unregTokenizer();
                this.editFunctions.splice(i, 1); // Tab entfernen
                if (i <= this.currentIndex) // befand sich vor dem aktiven Tab oder war es selbst
                    this.currentIndex--; // aktiver Tab hat einen kleineren Index
            }
        }
        if (this.currentIndex >= 0)
            this.update(this.editFunctions[this.currentIndex]);
    }
    stopEdit(f: EditLoop) { // Ein Tab wird geschlossen
        let i = this.editFunctions.indexOf(f); // Index des zu schließenden Tabs
        this.editFunctions[i].unregTokenizer();
        this.editFunctions.splice(i, 1); // Tab entfernen
        if (i <= this.currentIndex) // befand sich vor dem aktiven Tab oder war es selbst
            this.currentIndex--; // aktiver Tab hat einen kleineren Index
        if (this.currentIndex >= 0)
            this.update(this.editFunctions[this.currentIndex]);
    }
    saveChanges(f: EditLoop) {
        if (!f.canSave) return;
        if (!f.original) {
            f.original = f.currentLoop;
            this.db.addLoop(f.original);
        }
        else {
            f.original.name = f.currentLoop.name;
            f.original.comment = f.currentLoop.comment;
            f.original.statements = f.currentLoop.statements;
            f.original.type = f.currentLoop.IsWhile() ? LoopType.USERWHILE : LoopType.USERLOOP;
            this.db.saveLoop();
            for (let uloop of this.db.usedByLoop(f.original, true)) {
                if (!uloop.isBasic())
                    uloop.type = uloop.IsWhile() ? LoopType.USERWHILE : LoopType.USERLOOP;
            }
        }
        this.update(f);
    }
}
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Loop } from '../loop';
import { Sampler, SampleService, Sample } from '../../general/sample.service';

@Component({
  selector: 'loop-eval',
  templateUrl: './loopeval.component.html',
  styleUrls: ['./loopeval.component.css']
})
export class LoopEvalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() sampleloop: Loop;
  @Input() canClose: boolean;
  @Output() wantClose = new EventEmitter<boolean>();
  samples: Array<Sample> = undefined;
  sampler: Sampler = undefined;
  subscription;
  expecting: number = 0;
  constructor(private sample: SampleService) {
    this.samples = [];
  }
  closeself() {
    if (this.canClose) {
      this.destroy();
      this.wantClose.emit(true);
    }
  }
  destroy() {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.sampler) {
      this.sampler.terminate();
      this.sampler = undefined;
    }
    this.samples = [];
  }
  ngOnDestroy(): void {
    this.destroy();
  }
  public ngOnInit(): void {
    this.initSampler();
  }
  ngOnChanges(): void {
    this.initSampler();
  }
  initSampler() {
    this.destroy();
    this.sampler = this.sampleloop ? this.sample.getLoopSampler(this.sampleloop,this.sampleloop.nParam) : undefined;
    if (this.sampler) {
      this.expecting = 20;
      this.subscription = this.sampler.nextSample.subscribe((sample) => {
        this.samples[sample.id].result = sample.result;
        clearTimeout(this.samples[sample.id].canStopTimeout);
        this.samples[sample.id].canStop = false;
        this.expecting--;
        if (this.sampler && this.expecting > 0)
          this.addSample(this.sampler.startNextSample());
      })
      this.addSample(this.sampler.startNextSample());
    }
  }
  addSample(sample: Sample) {
    if (sample)
      this.samples.push(sample);
  }
  skipDoNext(stopSample: Sample) {
    stopSample.result = "?";
    stopSample.canStop = false;
    this.sampler.terminate();
    this.expecting = 0;
  }
  restart(s: Sample) {
    this.expecting = 1;
    this.sampler.startNextSample(s);
  }
  moresamples() {
    this.expecting = 20;
    this.addSample(this.sampler.startNextSample());
  }
}


<div class="prf-app">
    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav mode="side" [opened]="true">
            <prf-base></prf-base>
        </mat-sidenav>
        <mat-sidenav-content>
            <button mat-mini-fab color="primary" (click)="onNewFunction()" matTooltip="{{ 'CREATENEW' | translate}}" style="position:absolute;margin-top:5px;margin-left:5px;z-index:1000">
                <mat-icon>add</mat-icon>
            </button>
            <mat-tab-group #tabs [selectedIndex]="es.currentIndex">
                <mat-tab *ngFor="let f of es.editFunctions">
                    <ng-template mat-tab-label>
                        <span style="flex:1">{{f.currentName}}</span>
                            <mat-icon (click)="close(f)" color="warn" class="closebutton" matTooltip="{{ 'CLOSETAB' | translate}}">clear</mat-icon>
                    </ng-template>
                    <prf-editor #editor [func]="f"></prf-editor>
                </mat-tab>
            </mat-tab-group>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
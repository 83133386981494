/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../safehtml.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./confirm.component";
var styles_ConfirmComponent = [i0.styles];
var RenderType_ConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmComponent, data: {} });
export { RenderType_ConfirmComponent as RenderType_ConfirmComponent };
export function View_ConfirmComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i4.MatDialogTitle, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 9, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(12, 0, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "span", [["style", "flex:1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["cdkFocusInitial", ""], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).dialogRef.close(i1.ɵnov(_v, 16).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(16, 606208, null, 0, i4.MatDialogClose, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(17, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_10 = "primary"; _ck(_v, 15, 0, currVal_10); var currVal_11 = true; _ck(_v, 16, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), _co.message)); _ck(_v, 6, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 11).disabled || null); var currVal_4 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = _co.cancelText; _ck(_v, 12, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 15).disabled || null); var currVal_7 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); var currVal_8 = (i1.ɵnov(_v, 16).ariaLabel || null); var currVal_9 = i1.ɵnov(_v, 16).type; _ck(_v, 14, 0, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_12 = _co.okText; _ck(_v, 17, 0, currVal_12); }); }
export function View_ConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ConfirmComponent_0, RenderType_ConfirmComponent)), i1.ɵdid(1, 49152, null, 0, i9.ConfirmComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], null, null); }
var ConfirmComponentNgFactory = i1.ɵccf("ng-component", i9.ConfirmComponent, View_ConfirmComponent_Host_0, {}, {}, []);
export { ConfirmComponentNgFactory as ConfirmComponentNgFactory };

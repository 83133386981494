import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { EditPRF, PRFEditingService } from '../prfediting.service';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { PRFFormattingService } from '../prfformatting.service';
import { PRF, PRFType } from '../prf';
import { MatDialog } from '@angular/material/dialog';
import { PRFGeneratorComponent } from '../generator/generator.component';
import { LoopEditingService } from '../../loop/loopediting.service';
import { Router } from '@angular/router';
import { TransformService } from '../../general/transform.service';
import { DlgJSComponent } from '../../general/dlgjs/dlgsj.component';
import { TranslateService } from '@ngx-translate/core';
import { CodeService } from '../../general/code.service';
import { LoopParsingService } from 'src/app/loop/loopparsing.service';

@Component({
  selector: 'prf-editor',
  templateUrl: './prfeditor.component.html',
  styleUrls: ['./prfeditor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PRFEditorComponent implements OnInit {
  public config = {
    //mode: 'cmprfmode',
    matchBrackets: true,
    autoCloseBrackets: true,
    singleCursorHeightPerLine:false
  }
  @ViewChild('cm', { static: true }) cm:CodemirrorComponent;
  @ViewChildren('hcm') hcm:QueryList<CodemirrorComponent>;
  @Input() func: EditPRF;
  sampleprf: PRF;
  showEval: boolean;
  constructor(private es: PRFEditingService,
    private fmt: PRFFormattingService,
    public dialog: MatDialog,
    private loopEditorService: LoopEditingService,
    private parser: LoopParsingService,
    private transform: TransformService,
    private translate: TranslateService,
    private coding:CodeService,
    private router: Router) {
  }
  
  public ngOnInit(): void {
    this.update(); // Update after opening
  }
  public update(): void{
    setTimeout(() => {
      this.cm.codeMirror.setOption("mode", this.func.editID);
      this.hcm.forEach((hc, i) => hc.codeMirror.setOption("mode", this.func.currentHelper[i].editID))
    },50);
  }
  public onChange(item:number) {
    if (this.func.isBasic()) return;
    this.es.update(this.func);
    this.update();
    this.startsampling(item);
  }
  addHelper(index: number) {
    this.es.addHelper(this.func, index);
    this.update();
  }
  removeHelper(index: number) {
    if (this.sampleprf == this.func.currentHelper[index].currentPRF) {
      this.sampleprf = undefined;
    }
    this.es.removeHelper(this.func, index);
    this.update();
  }
  save() {
    this.es.saveChanges(this.func);
    this.update();
  }
  startsampling(item: number) {
    this.sampleprf = item == -1 ? this.func.currentPRF : this.func.currentHelper[item].currentPRF;
  }
  openEval(item: number) {
    this.showEval = true;
    this.startsampling(item);
  }
  closeeval() {
    this.showEval = false;
  }
  
  generate(item: number) {
    this.dialog.open(PRFGeneratorComponent, {
      data: {
        name: "h",
        number: undefined
      }
    }).afterClosed().subscribe(selected => {
      if (selected) {
        let editprf = item == -1 ? this.func : this.func.currentHelper[item];
        editprf.currentCode = selected.toString();
        this.onChange(item);
      }
    });
  }
  convertToLoop() {
    let [name, n, code] = this.transform.prf2loop(this.func.currentPRF)
    this.loopEditorService.create(name, n, code, true);
    this.router.navigateByUrl("/loop");
  }
  showJS() {
    let code = this.coding.getPRFCode(this.func.currentPRF,false)
    for (let func of this.func.currentPRF.addDependencies(new Set<PRF>([this.func.currentPRF]), true)) {
      if (func != this.func.currentPRF && func.type != PRFType.HELP)
        code += this.coding.getPRFCode(func,true);
    }
    this.dialog.open(DlgJSComponent, {
      data: {
        code: code,
        title: this.func.currentName,
        okText: this.translate.instant("CLOSE")
      }
    });
  }
}


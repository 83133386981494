import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ErrorService {
    constructor(private snackBar: MatSnackBar, private translate:TranslateService) {
    }
    show(message, messageParam=undefined) {
        this.snackBar.open(this.translate.instant(message,messageParam), "", { duration: 4000 });
    }
}
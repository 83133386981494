import { Injectable } from '@angular/core';
import { PRF, VARARGS, PRFType } from '../prf/prf'
import { PRFParsingService } from '../prf/prfparsing.service';
import { Loop, LoopType } from '../loop/loop';
import { CoMoSimError } from './comosimerror';
import { LoopParsingService } from '../loop/loopparsing.service';
import { PRFEditingService } from '../prf/prfediting.service';

// Verwaltung der Funktionsbasis (Laden, Speichern, ...)
@Injectable()
export class DatabaseService {
    public functions: PRF[];
    public loops: Loop[];
    public sPRF: PRF = new PRF(PRFType.SUCC, "S", undefined, ["a"], "SUCCESSORFUNCTION");
    public cPRF: PRF = new PRF(PRFType.CONS, "C", "i", [VARARGS], "CONSTANTFUNCTIONS");
    public uPRF: PRF = new PRF(PRFType.PROJ, "U", "i", ["a", VARARGS], "PROJECTIONFUNCTIONS");
    public encPRF: PRF = new PRF(PRFType.ENC, "enc", undefined, ["a", VARARGS], "ENCODINGFUNCTIONS");
    public decPRF: PRF = new PRF(PRFType.DEC, "dec", "i", ["c"], "DECODINGFUNCTIONS");
    public sLoop: Loop = new Loop(LoopType.SUCC, "succ", undefined, 1, "SUCCESSORFUNCTION");
    public encLoop: Loop = new Loop(LoopType.ENC, "enc", undefined, undefined, "ENCODINGFUNCTIONS");
    public decLoop: Loop = new Loop(LoopType.DEC, "dec", "i", 1, "DECODINGFUNCTIONS");
    constructor(private parserPRF: PRFParsingService, private parserLoop:LoopParsingService) {
        this.functions = []
        this.loops = []
        this.loadPRF();
        this.loadLoop();
    }
    private getBasePRFFunctions(): Array<PRF> { // Die Basisfunktionen
        return [this.sPRF, this.cPRF, this.uPRF, this.encPRF, this.decPRF];
    }
    private getBaseLoopFunctions(): Array<Loop> { // Die Basisfunktionen
        return [this.sLoop, this.encLoop, this.decLoop];
    }
    private addDeserializedPRF(s: string, es:PRFEditingService=undefined) {
        if (!s) return;
        let sf = [];
        try {
            sf = JSON.parse(s);
        }
        catch (err) {
            console.log(err.name + ': ' + err.message);
            throw new CoMoSimError("JSONERROR", { msg: err.name + ': ' + err.message})
        }
        while (sf.length > 0) {
            let lastlen = sf.length;
            let rest = [];
            for (let o of sf) {
                try {
                    let prf = this.parserPRF.deserializePRF(o, this.functions);
                    this.functions.push(prf);
                }
                catch (err) {
                    if (err instanceof (CoMoSimError))
                        console.log(err.msg)
                    else
                        console.log(err.name + ": " + err.message);
                    rest.push(o);
                }
            }
            sf = rest;
            if (sf.length == lastlen) {
                if (!es)
                    throw new CoMoSimError("DESERIALIZATIONERROR");
                // Via Editing Service versuchen die Funktion anzuzeigen
                return;
            }
        }
    }
    private addDeserializedLoop(s: string, es: PRFEditingService = undefined) {
        if (!s) return;
        let sf = [];
        try {
            sf = JSON.parse(s);
        }
        catch (err) {
            console.log(err.name + ': ' + err.message);
            throw new CoMoSimError("JSONERROR", { msg: err.name + ': ' + err.message })
        }
        while (sf.length > 0) {
            let lastlen = sf.length;
            let rest = [];
            for (let o of sf) {
                try {
                    let lf = this.parserLoop.deserializeLoop(o, this.loops);
                    this.loops.push(lf);
                }
                catch (err) {
                    if (err instanceof (CoMoSimError))
                        console.log(err.msg)
                    else
                        console.log(err.name + ": " + err.message);
                    rest.push(o);
                }
            }
            sf = rest;
            if (sf.length == lastlen) {
                if (!es)
                    throw new CoMoSimError("DESERIALIZATIONERROR");
                // Via Editing Service versuchen die Funktion anzuzeigen
                return;
            }
        }
    }
    public loadPRF(): void {
        this.functions.splice(0, this.functions.length); // Vorhandene Funktionen entfernen
        this.getBasePRFFunctions().forEach(bf => this.functions.push(bf)); // Basisfunktionen einfügen
        this.addDeserializedPRF(localStorage.getItem("AllPRF")); // Gespeicherte Funktionen hinzufügen
    }
    public loadLoop(): void {
        this.loops.splice(0, this.loops.length); // Vorhandene Funktionen entfernen
        this.getBaseLoopFunctions().forEach(bf => this.loops.push(bf)); // Basisfunktionen einfügen
        this.addDeserializedLoop(localStorage.getItem("AllLOOP")); // Gespeicherte Funktionen hinzufügen
    }
    public loadFromJSONPRF(ls) {
        let backup = this.functions.slice();
        this.functions.splice(0, this.functions.length); // Vorhandene Funktionen entfernen
        this.getBasePRFFunctions().forEach(bf => this.functions.push(bf)); // Basisfunktionen einfügen
        try {
            this.addDeserializedPRF(ls);
            this.savePRF();
        }
        catch (err) {
            this.functions.splice(0, this.functions.length);
            backup.forEach(b => this.functions.push(b));
            if (err instanceof (CoMoSimError))
                throw err;
            else
                throw new CoMoSimError("UNKNOWNERROR", { msg: err.name + ': ' + err.message })
        }
    }
    public loadFromJSONLoop(ls) {
        let backup = this.loops.slice();
        this.loops.splice(0, this.loops.length); // Vorhandene Funktionen entfernen
        this.getBaseLoopFunctions().forEach(bf => this.loops.push(bf)); // Basisfunktionen einfügen
        try {
            this.addDeserializedLoop(ls);
            this.saveLoop();
        }
        catch (err) {
            this.loops.splice(0, this.loops.length);
            backup.forEach(b => this.loops.push(b));
            if (err instanceof (CoMoSimError))
                throw err;
            else
                throw new CoMoSimError("UNKNOWNERROR", { msg: err.name + ': ' + err.message })
        }
    }
    public deleteAll():void {
        localStorage.removeItem("AllPRF")
        localStorage.removeItem("AllLOOP")
        this.loadPRF();
        this.loadLoop();
    }
    public getJSONPRF(option:number=undefined):string {
        return JSON.stringify(this.functions.filter(f => !f.isBasic()).map(f => f.serialize()),null,option);
    }
    public getJSONLoop(option: number = undefined): string {
        return JSON.stringify(this.loops.filter(f => !f.isBasic()).map(f => f.serialize()), null, option);
    }
    public savePRF():void {
        localStorage.setItem("AllPRF", this.getJSONPRF());
    }
    public saveLoop(): void {
        localStorage.setItem("AllLOOP", this.getJSONLoop());
    }
    public addPRF(f: PRF):boolean {
        if (f.type != PRFType.NEW)
            return false;
        f.type = f.isMu() ? PRFType.USERMU : PRFType.USERPRIM;
        this.functions.push(f);
        this.savePRF();
        return true;
    }
    public addLoop(f: Loop): boolean {
        if (f.type != LoopType.NEW)
            return false;
        f.type = f.IsWhile() ? LoopType.USERWHILE : LoopType.USERLOOP;
        this.loops.push(f);
        this.saveLoop();
        return true;
    }
    public usedByPRF(prf: PRF,deep:boolean=true): Set<PRF>{
        let r = new Set<PRF>([prf]);
        for (let f of this.functions) {
            let d = f.addDependencies(new Set<PRF>([f]), deep);
            if (d.has(prf))
                r.add(f);
        }
        r.delete(prf);
        return r;
    }
    public usedByLoop(lf: Loop, deep: boolean = true): Set<Loop> {
        let r = new Set<Loop>([lf]);
        for (let f of this.loops) {
            let d = f.addDependencies(new Set<Loop>([f]), deep);
            if (d.has(lf))
                r.add(f);
        }
        r.delete(lf);
        return r;
    }
    
    public deletePRF(delprf: Set<PRF>): void {
        for (let i = this.functions.length; i > 0; i--){
            if (delprf.has(this.functions[i - 1])) {
                this.functions.splice(i - 1, 1);
            }
        }
        this.savePRF();
    }
    public deleteLoop(dellf: Set<Loop>): void {
        for (let i = this.loops.length; i > 0; i--) {
            if (dellf.has(this.loops[i - 1])) {
                this.loops.splice(i - 1, 1);
            }
        }
        this.saveLoop();
    }
}

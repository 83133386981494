import { Injectable, Output, EventEmitter } from "@angular/core";
import { PRF, VARARGS, PRFType } from "../prf/prf";
import { Loop } from "../loop/loop";
import { CodeService, blacklist } from "./code.service";
export class Sample{
    constructor(name:String, id: number, args: Array<number>) {
        this.name = name;
        this.id = id;
        this.args = args;
        this.result = "?";
        this.canStop = false;
    }
    toString(): String{
        return this.name + "(" + this.args.join(",") + ") = " + this.result;
    }
    name: String;
    id: number;
    args: Array<number>;
    result: String;
    canStop: boolean;
    canStopTimeout;
}
export class Sampler {
    code: String;
    fname: String;
    @Output() nextSample: EventEmitter<Sample> = new EventEmitter();
    worker: Worker;
    arggen: Generator;
    curId: number;
    constructor(code: string, paramLength: number, fname: string) {
        this.arggen = this.generateArgs(paramLength);
        this.curId = 0;
        this.code = code;
        this.fname = fname;
    }
    terminate() {
        if (!this.worker) return;
        this.worker.terminate();
        this.worker = undefined;
    }
    startNextSample(sample: Sample = undefined): Sample {
        if (!sample) {
            let args = this.arggen.next();
            if (args.done) return undefined;
            sample = new Sample(this.fname, this.curId++, args.value);
        }
        if (!this.worker) {
            this.worker = new Worker("./assets/evalworker.js");
            this.worker.postMessage({ msg: "init", code: this.code });
            this.worker.addEventListener("message", (e) => {
                this.nextSample.emit(e.data);
            })
        }
        sample.result = "⧖";
        sample.canStopTimeout = setTimeout(() => sample.canStop = true, 1000);
        this.worker.postMessage({ msg: "calc", sample: sample });
        return sample;
    }
    next(n: number) {
        this.worker.postMessage(n);
    }
    *generateArgs(paramLength:number) {
        if (paramLength == 0) {
            yield [];
            return;
        }
        function* tuples(n:number, max:number, a:Array<number>) {
            if (n == 0) {
                if (a.indexOf(max) >= 0)
                    yield a;
                return;
            }
            for (let i = 0; i <= max; i++)
                yield* tuples(n - 1, max, a.concat([i]));
        }
        for (let i = 0; i <= Infinity; i++)
            yield* tuples(paramLength, i, [])
    }
}

@Injectable()
export class SampleService {
    constructor(private coding: CodeService,) { }
    getPRFSampler(f: PRF) {
        let code = this.coding.getPRFCode(f,false);
        for (let func of f.addDependencies(new Set<PRF>([f]), true)) {
            if (f != func && (f.type == PRFType.HELP || func.type != PRFType.HELP))
                code += this.coding.getPRFCode(func);
        }
        let par: Array<string> = f.param.filter(p => p != VARARGS);
        for (let i = 0; i < par.length; i++){
            if (blacklist.indexOf(par[i]) >= 0)
                par[i] = "_" + par[i];
        }
        let name = f.name;
        if (blacklist.indexOf(name) >= 0)
            name = "_" + name;
        code += "return " + name.replace(/\./g, "_") + ".apply(undefined, arguments);";
        return new Sampler(code, par.length, f.name);
    }
    getLoopSampler(f: Loop, nArgs:number) {
        let code = ""
        for (let func of f.addDependencies(new Set<Loop>([f]), true)) {
            code += this.coding.getLoopCode(func);
        }
        let name = f.name;
        if (blacklist.indexOf(name) >= 0)
            name = "_" + name;
        code += "return " + name.replace(/\./g, "_") + ".apply(undefined, arguments);";
        return new Sampler(code, nArgs, f.name);
    }
}
import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  languages = [
    { key: "en", name: "English", img: "flag-en" },
    { key: "de", name: "Deutsch", img: "flag-de" }
  ];
  lang = 0;
  apps = [
    // { icon: "whatshot", name: "COMOSIM", route: "/home" },
    // { icon: "blur_on", name: "COMOSIM", route: "/home" },
    // { icon: "all_inclusive", name: "COMOSIM", route: "/home" },
    { icon: "apps", name: "COMOSIM", route: "/home" },
    { icon: "looks_one", name: "PRF", route: "/prf" },
    { icon: "cached", name: "LOOP", route: "/loop" }
  ]
  app = this.apps[0];
  downloads = [
    { name: "MASTERTHESIS", file: "prala-master-thesis.pdf" },
    { name: "SOURCECODE", file: "comosim-source.zip" },
    { name: "DISTRIBUTION", file: "comosim-dist.zip" },
    { name: "EXAMPLELOOP", file: "example-loop.json" },
    { name: "EXAMPLEPRF", file: "example-prf.json" }
  ]
  
  constructor(private router: Router, private translate: TranslateService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    // if (navigator.userAgent.toLowerCase().indexOf(' electron/') == -1) {
    //   this.downloads.push({ name: "WINDOWSAPP", file: "comosim-win32-x64.zip" });
    //   this.downloads.push({ name: "LINUXAPP", file: "comosim-linux-x64.zip" });
    //   this.downloads.push({ name: "MACAPP", file: "comosim-mac-darwin.zip" });
    // }
    iconRegistry.addSvgIcon('flag-de', sanitizer.bypassSecurityTrustResourceUrl('assets/de.svg'));
    iconRegistry.addSvgIcon('flag-en', sanitizer.bypassSecurityTrustResourceUrl('assets/gb.svg'));
    translate.addLangs(this.languages.map(l=>l.key));
    translate.setDefaultLang(this.languages[this.lang].key);
    let bl = this.languages.findIndex(l => l.key == translate.getBrowserLang());
    this.lang = bl == -1 ? 0 : bl;
    let storedLang = parseInt(localStorage.getItem("lang"));
    if (typeof (storedLang) == "number" && storedLang >= 0 && storedLang < this.languages.length)
      this.lang = storedLang;
    translate.use(this.languages[this.lang].key);
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let routeapp = this.apps.find(a => a.route == val.urlAfterRedirects)
        if (routeapp)
          this.app = routeapp
        else
          this.app = this.apps[0];
      }
    });
    // for (let i = this.downloads.length; i > 0; i--){
    //   if (!this.doesFileExist("./downloads/" + this.downloads[i - 1].file))
    //     this.downloads.splice(i - 1, 1);
    // }
  }
  // private doesFileExist(urlToFile) {
  //   var xhr = new XMLHttpRequest();
  //   xhr.open('HEAD', urlToFile, false);
  //   xhr.send();
  //   return !(xhr.status == 404)
  // }
  onLanguage(l:number) {
    this.lang = l;
    this.translate.use(this.languages[this.lang].key);
    localStorage.setItem("lang",this.lang.toString());
  }
  onApp(a) {
    this.router.navigateByUrl(a.route);
  }
  onDownload(d) {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.setAttribute("download", d.file);
    link.href = "./downloads/"+d.file;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}

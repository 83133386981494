import { DatabaseService } from "../general/database.service";
import { Injectable } from "@angular/core";
import { PRF, PRFType, VARARGS } from "./prf";

export class PRFTokenizer {
    getFunctions: Function;
    constructor(getFns: Function) {
        this.getFunctions = getFns;
    }
    startState() {
        return {
            vars: {},
            f: this.getFunctions()
        };
    }
    token(stream, state) {
        if ("(),= ".includes(stream.peek())) {
            stream.next()
            return null;
        }
        else if ("[]".includes(stream.peek())) {
            stream.next()
            return "mu";
        }
        else {
            let next = stream.string.substring(stream.pos).search(/[\(\),]/);
            if (next == -1) {
                stream.next();
                return null;
            }
            next += stream.pos;
            if (stream.string[next] != "(") {
                let v = ""
                while (stream.pos < next)
                    v += stream.next();
                v = v.trim();
                if (!v.match(/^(0|[1-9]\d*)$/)) {
                    if (v == VARARGS)
                        return "var-args";
                    if (v == "#")
                        return "mu";
                    if (!state.vars[v])
                        state.vars[v] = "var-" + ((Object.keys(state.vars).length) % 6);
                    return state.vars[v];
                }
                else
                    return null;
            }
            else{
                let v = ""
                while (stream.pos < next)
                    v += stream.next();
                v = v.trim();
                let n = v.split("_")[0];
                for (let func of state.f) {
                    if (func.name == n)
                        return func.type == PRFType.HELP ? "help" : "known";
                }
                return "unknown";
            }
        }
    }
}

class MyStringStream{
    string: string;
    pos: number;

    constructor(s:string) {
        this.pos = 0;
        this.string = s;
    }
    peek() { return this.string.charAt(this.pos) || undefined };
    next() {
        if (this.pos < this.string.length) { return this.string.charAt(this.pos++) }
    };
}
@Injectable()
export class PRFFormattingService {
    public tokenizer: PRFTokenizer;
    constructor(private db: DatabaseService) {
        this.tokenizer = new PRFTokenizer(() => {
            return db.functions.reduce((l, f) => {
                l.push(f);
                return f.helper ? l.concat(f.helper) : l;
            }, [])
        });
    }
    public colorizeFormula(s: string) {
        let state = this.tokenizer.startState();
        let result = "";
        for (let line of s.split("\n")) {
            if (result != "") result += "\n"
            let stream = new MyStringStream(line);
            let lastpos = 0;
            while (stream.pos < stream.string.length) {
                let t = this.tokenizer.token(stream, state);
                if (!t)
                    result += stream.string.substring(lastpos, stream.pos)
                else
                    result += "<span class='cm-" + t + "'>" + stream.string.substring(lastpos, stream.pos) + "</span>"
                lastpos = stream.pos;
            }
        }
        return result;
    }
}

<mat-toolbar style="height:48px;display:flex" color="secondary">
  <span translate>EVAL</span>
  <span style="flex:1"></span>
  <button mat-button mat-icon-button color="warn" (click)="closeself()" *ngIf="canClose" style="margin-left:10px" matTooltip="{{ 'CLOSEEVAL' | translate}}">
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>
<mat-nav-list style="overflow-x:hidden;overflow-y:scroll;max-width:400px;padding-top:0;flex:1">
  <mat-divider></mat-divider>
  <mat-list-item *ngFor="let s of samples" style="cursor:default;display:flex">
    <p matLine class="mono" style="font-size:18px" [innerHTML]="s.toString() | safeHtml"> </p>
    <mat-divider style="width:100%"></mat-divider>
    <button mat-button mat-icon-button (click)="skipDoNext(s)" *ngIf="s.canStop" style="margin-right:0px" matTooltip="{{ 'STOPEVAL' | translate}}">
      <mat-icon>stop</mat-icon>
    </button>
    <button mat-button mat-icon-button (click)="restart(s)" [disabled]="expecting != 0" *ngIf="s.result=='?'" style="margin-right:0px" matTooltip="{{ 'RESTARTEVAL' | translate}}">
      <mat-icon>play_arrow</mat-icon>
    </button>
  </mat-list-item>
  <mat-list-item *ngIf="expecting == 0" (click)="moresamples()">
    <p matLine style="font-size:18px" translate>MORE</p>
    <mat-divider></mat-divider>
  </mat-list-item>
  <!-- <mat-list-item *ngIf="this.expecting > 0">
    <p matLine style="font-size:18px" translate [translateParams]="{func: this.sampleloop.name}">CALCULATING</p>
    <mat-divider></mat-divider>
  </mat-list-item> -->
</mat-nav-list>



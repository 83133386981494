<div class="app-container">
  <mat-toolbar color="primary" class="app-toolbar">
    <button mat-icon-button [matMenuTriggerFor]="menuApp" matTooltip="{{ 'CHANGEAPP' | translate}}">
      <mat-icon>{{app.icon}}</mat-icon>
    </button>
    <span style="user-select:none">{{app.name | translate}}</span>
    <mat-menu #menuApp="matMenu">
      <button mat-menu-item *ngFor="let a of apps" (click)="onApp(a)">
        <mat-icon>{{a.icon}}</mat-icon>
        <span translate>{{a.name}}</span>
      </button>
    </mat-menu>
    <span style="flex:1"></span>
    <button mat-icon-button [matMenuTriggerFor]="menuLang">
      <mat-icon class="largeIcon" svgIcon="{{languages[lang].img}}"></mat-icon>
    </button>
    <mat-menu #menuLang="matMenu">
      <button mat-menu-item *ngFor="let l of languages; let idx = index" (click)="onLanguage(idx)">
        <mat-icon class="largerIcon" svgIcon="{{l.img}}"></mat-icon>
        <span>{{l.name}}</span>
      </button>
    </mat-menu>
  </mat-toolbar>
  <div class="route-content">
    <router-outlet></router-outlet>
  </div>
  <mat-toolbar color="primary" class="app-footer">
    <span style="font-size:12px;margin:auto" [innerHTML]="'IMPRESSUM'|translate"></span>
    <mat-menu #menuDownloads="matMenu">
      <button mat-menu-item *ngFor="let d of downloads" (click)="onDownload(d)">
        <span translate>{{d.name}}</span>
      </button>
    </mat-menu>
    <button mat-stroked-button *ngIf="downloads.length" [matMenuTriggerFor]="menuDownloads" style="height:22px;line-height:22px;font-size:12px;border-color:white">Downloads</button>
  </mat-toolbar>
</div>
<div layout="row" style="display:flex;overflow:hidden;height:100%">
  <div style="flex:1;display:flex;flex-direction:column;">
    <div style="display:flex">
      <mat-form-field style="margin:10px;margin-bottom:-15px;flex:1">
        <input matInput [(ngModel)]="func.currentName" placeholder="{{'NAME' | translate}}" (ngModelChange)="onChange()" [disabled]="func.isBasic()">
      </mat-form-field>
      <mat-form-field style="margin:10px;margin-bottom:-15px;width:40px" matTooltip="{{ 'NUMBEROFPARAMS' | translate}}">
        <mat-label>n</mat-label>
        <mat-select #nparams [(ngModel)]="func.currentParam" (selectionChange)="onChange()" [disabled]="func.isBasic()">
          <mat-option *ngFor="let n of nums" [value]="n">{{n}}</mat-option>
          <button mat-button style="line-height:3em;width:100%;text-align:left" (click)="morenums()">
            <p style="margin:5px;font-size:inherit" translate>MORE</p>
          </button>
        </mat-select>
      </mat-form-field>
      <button mat-button mat-icon-button style="margin-top:8px;" (click)="expandLoop()" [disabled]="!func.currentLoop" matTooltip="{{ 'EXPANDLOOP' | translate}}">
        <mat-icon>unfold_more</mat-icon>
      </button>
      <button mat-button mat-icon-button style="margin-top:8px;" (click)="convertToPRF()" [disabled]="!func.currentLoop" matTooltip="{{ 'CONVERT2PRF' | translate}}">
        <mat-icon>swap_calls</mat-icon>
      </button>
      <button mat-button mat-icon-button style="margin-top:8px;" (click)="showJS()" [disabled]="!func.currentLoop" matTooltip="{{ 'JSCODE' | translate}}">
        <b>JS</b>
      </button>
      <span style="margin:5px;">
      <button mat-raised-button color="primary" style="margin:5px" (click)="save()" [disabled]="!func.canSave" matTooltip="{{ 'SAVEFUNCTION' | translate}}">
        <span translate>SAVE</span>
      </button>
      </span>
    </div>
    <div style="display:flex">
      <mat-form-field style="margin:10px;margin-bottom:-15px;flex:1">
        <input matInput [(ngModel)]="func.currentComment" placeholder="{{'DESCRIPTION' | translate}}" (ngModelChange)="onChange()" [disabled]="func.isBasic()">
      </mat-form-field>
    </div>
    <div style="display:flex;flex:1;overflow:auto">
      <ngx-codemirror class="cmloop mono" #cm [(ngModel)]="func.currentCode" [options]="config" [autoFocus]="true" (ngModelChange)="onChange()"  [disabled]="func.isBasic()"></ngx-codemirror>
      <button mat-raised-button color="primary" class="openevalbutton" (click)="openEval()" [disabled]="!func.currentLoop" matTooltip="{{ 'SHOWEVAL' | translate}}">
        <mat-icon>play_arrow</mat-icon>
      </button>
    </div>
    <div layout="row" style="display:flex;height:50px">
      <span id="infopanel" [class]="func.msgType" [innerHTML]="func.message | translate:func.messageParam"></span>
    </div>
  </div>
  <mat-divider [vertical]="true"></mat-divider>
  <loop-eval style="display:flex;flex-direction:column" *ngIf="showEval" [sampleloop]="sampleloop" canClose="true" (wantClose)="closeeval()"></loop-eval>
</div>


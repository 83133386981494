import { Injectable } from '@angular/core';
import { DatabaseService } from "../general/database.service"
import { PRF, VARARGS, Equation, Func, PRFType } from "./prf"

// Substitutionen und Rekursionen generieren
@Injectable()
export class PRFGeneratorService {
    constructor(private db: DatabaseService) {
    }
    *generateCompositions(n: number = 1,name:string="h",nargs:number=undefined) {
        yield* compositionPRF(this.db.functions, n, name, nargs);
    }
    *generateRecursions(name: string = "h", nargs: number = undefined) {
        yield* primitiveRecPRF(this.db.functions, name, nargs);
    }
}

function createargs(min: number, max: number):Array<string> { // Argumentliste für den min-max-Bereich
    let args = [] // die Parameterliste der Komposition
    for (let i = 0; i < min; i++) // Minimale Anzahl von Parametern
        args.push("a"+(i+1))
    if (max == Infinity) // ist entweder min oder Infinity
        args.push(VARARGS);
    return args;
}
function getIndex(func:PRF): string | number {
    if (func.type == PRFType.CONS)
        return 0;
    else if (func.type == PRFType.PROJ)
        return 1;
    return undefined;
}
function getIndexOptions(func: PRF, nargs: number): Array<string | number> {
    if (func.type == PRFType.CONS)
        return [0];
    else if (func.type == PRFType.PROJ)
        return Array.from(new Array(nargs), (val, index) => index + 1);
    else if (func.type == PRFType.DEC)
        return [0]; // Bei Generierung nur mit Index 0
    return [undefined];
}
function getIndexCombinations(fn: Array<PRF>, nargs: number): Array<Array<string | number>> {
    let result = [[]]; // Pro Kombination ein Eintrag mit einem Array der Indexe für fn
    for (let f of fn) {
        let res = []
        for (let io of getIndexOptions(f, nargs)) {
            for (let r of result) {
                res.push(r.concat([io]));
            }
        }
        result = res;
    }
    return result;
}
// Ergebnisse durch Substitution
function* compositionPRF(functions: Array<PRF>, n: number = 1, nametemplate:string, nargs: number): Iterable<PRF> { // n=Anzahl der eingebetteten Funktion
    // h(#*) = f(g1(#*), ..., gn(#*))
    let name = nametemplate;
    let i = 1;
    while (functions.find(f => f.name == name)) {
        name = nametemplate + i.toString();
        i++;
    }
    function* compseq(n):Iterable<[Array<PRF>,number,number]> { // Generierung der Funktionen-Sequenz, n = Länge der Sequenz
        for (let f of functions) { // alle Funktionen sind möglich
            let [min, max] = f.getMinMaxParam();
            if (nargs != undefined && (nargs < min || nargs > max))
                continue; // Unterstützt nicht die geforderte Stelligkeit
            if (n == 1) // Letzes Element der Sequenz
                yield [[f], min, max] // func(args)
            else { // Verkettung der Sequenz
                for (let [c, mi, ma] of compseq(n - 1)) // alle möglichen folgenden Sequenzen
                    yield [[f].concat(c), Math.max(min, mi), Math.min(max, ma)] // werden an aktuelles Element angehangen
            }
        }
    }
    if (n == 0) // n = 0 liefert keine neuen Funktionen
        return;
    for (let fn of functions) { // Jede Funktion kann als äußere Funktion dienen
        // Die geforderte Stelligkeit n muss aber möglich sein
        let [min, max] = fn.getMinMaxParam()
        if (n < min || n > max) continue; // Geforderte Stelligkeit der äußeren Funktion nicht möglich
        for (let [c, min, max] of compseq(n)) { // Sequenzen der möglichen inneren Funktionen
            if (min > max) continue; // Kombination wegen Unvereinbarkeit der Stelligkeit nicht möglich
            let args = nargs == undefined ? createargs(min, max) : createargs(nargs, nargs); // die Parameterliste der Komposition
            //TODO: bereits definitiert?
            //if (functions.find((f) => f[1] == rside)) continue; // Wurde diese Funktion bereits definiert
            let fixedArgs = args.length > 0 && args[args.length - 1] == VARARGS ? args.length - 1 : args.length;
            for (let fni of getIndexOptions(fn, c.length)) {
                for (let ic of getIndexCombinations(c, fixedArgs)) {
                    let res = new PRF(-1, name, undefined, args, ""); // Die Ergebnisfunktion
                    let rhsparam = c.map((cf,cfi) => new Func(cf, ic[cfi], args)); // Kompositionsparameter
                    res.definition = [new Equation(new Func(res, undefined, args), new Func(fn, fni, rhsparam))]; // Funktionsdefinition
                    yield res;
                }
            }
        }
    }
}

function* primitiveRecPRF(functions: Array<PRF>, nametemplate:string, nargs: number): Iterable<PRF> {
    // h(0,#*) = f(#*)
    // h(s(n),#*) = g(n,h(n,#*),#*)
    let name = nametemplate;
    let i = 1;
    while (functions.find(f => f.name == name)) {
        name = nametemplate + i.toString();
        i++;
    }
    for (let f of functions) { // f kann jede Funktion sein
        let [min, max] = f.getMinMaxParam() // Stelligkeit ermitteln
        if (nargs != undefined && (nargs-1 < min || nargs-1 > max))
            continue; // Unterstützt nicht die geforderte Stelligkeit
        for (let g of functions) { // potentiell jede Funktion als g möglich
            let [gmin, gmax] = g.getMinMaxParam() // Stelligkeit von g
            if (gmin > min + 2 || gmax < max + 2) continue; // lässt Nutzung als g nicht zu
            if (nargs != undefined && (nargs + 1 < gmin || nargs + 1 > gmax))
                continue; // Unterstützt nicht die geforderte Stelligkeit
            let args = nargs == undefined ? createargs(min, max) : createargs(nargs, nargs)
            let fArgs = args.length > 0 && args[args.length - 1] == VARARGS ? args.length - 1 : args.length;
            //TODO: bereits definitiert?
            for (let fi of getIndexOptions(f, fArgs)) {
                for (let gi of getIndexOptions(g, fArgs + 2)) {
                    let res = new PRF(-1, name, undefined, ["n"].concat(args), ""); // Die Ergebnisfunktion
                    let pa1: Array<string | Func> = [new Func(functions[1], 0, [])]; // Nullfunktion
                    pa1 = pa1.concat(args);
                    let pa2: Array<string | Func> = [new Func(functions[0], undefined, ["n"])]; // Nachfolgerfunktion
                    pa2 = pa2.concat(args);
                    res.definition = [
                        new Equation(new Func(res, undefined, pa1), new Func(f, fi, args)),
                        new Equation(new Func(res, undefined, pa2), new Func(g, gi, ["n", new Func(res, undefined, ["n"].concat(args))].concat(args)))
                    ]; // Funktionsdefinition
                    yield res
                }
            }
        }
    }
}
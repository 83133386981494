export const enum LoopType {
    SUCC = 0,   // Nachfolgerfunktion
    ENC = 3,    // Kodierungsfunktion
    DEC = 4,    // Dekodierungsfunktion
    USERLOOP = 10,  // Nutzerdefinierte Loop-Funktion
    USERWHILE = 11,  // Nutzerdefinierte While-Funktion
    NEW = -1    // Neue ungespeicherte Funktion
}

export class Loop {
    type: LoopType;             // Art der Funktion
    name: string;               // Name der Funktion: [A-za-z]+\d*
    index: string;              // Spezifizierung der Funktion: i bei dec
    nParam: number;             // Anzahl der Parameter
    statements: Array<LoopStatement>;  // Aufrufe des LOOP Programms
    comment: string;            // Textuelle Beschreibung der Funktion: "" ist erlaubt
    constructor(id: LoopType, name: string, index: string, nParam:number, comment: string) {
        this.type = id; this.name = name; this.index = index; this.nParam = nParam; this.comment = comment;
    }
    public GetFType():string {
        switch (this.type) {
            case LoopType.SUCC: return "B";
            case LoopType.ENC:
            case LoopType.DEC: return "H";
            case LoopType.USERLOOP: return "L";
            case LoopType.USERWHILE: return "W";
            default: return "?";
        }
    }
    public toString(): string { // Die vollständige Funktionsdefinition
        if (this.statements == undefined) // Bei Basisfunktionen
            return this.toCallString(); // ohne Definition
        return this.statements.map(d => d.toString()).join(";"); // Statements mit ; getrennt in einer Zeile
    }
    public toIndented(pre:string=""): string{ // Vollständiges Programm mit Einrückungen und Zeilenumbruch
        return this.statements.map(d => d.toIndented(pre)).join(";\n");
    }

    public toCallString(): string { // Nur der Funktionsaufruf
        if (this.type === LoopType.ENC)
            return this.name + "(a1,*)";
        if (this.type === LoopType.DEC)
            return this.name + "_"+this.index+"(c)";
        return this.name + "(" + Array.from(new Array(this.nParam), (val, i) => "a"+(i+1)).join(",")+")";
    }

    public isBasic(): boolean { // Eine Basisfunktion oder eine Systemfunktion?
        return (this.type == LoopType.SUCC ||
            this.type == LoopType.ENC ||
            this.type == LoopType.DEC)
    }

    public addDependencies(r: Set<Loop> = new Set<Loop>(), deep: boolean = true): Set<Loop> { // Abhängigkeiten dieser Funktion bestimmen
        if (this.statements) { // Abhängigkeiten der Definition
            for (let d of this.statements)
                d.addDependencies(r, deep);
        }
        return r;
    }

    public serialize(): object { // Serialisierbares Objekt erzeugen
        let o = {
            name: this.name,
            n:this.nParam,
            comment: this.comment,
            definition: undefined
        };
        if (this.statements)
            o.definition = this.statements.map(d => d.toString()).join(";"); // Definition serialisieren
        return o;
    }
    
    public getNumArgs():number {
        let readArgs = new Set<string>();
        let writeArgs = new Set<string>();
        for (let s of this.statements) {
            s.addReadArgs(readArgs, writeArgs)
        }
        let n = 0;
        for (let a of readArgs) {
            n = Math.max(n, parseInt(a.replace("x", "")));
        }
        return n;
    }
    public getImplicitVars():Set<string> {
        let readArgs = new Set<string>();
        let writeArgs = new Set<string>();
        for (let s of this.statements)
            s.addReadArgs(readArgs, writeArgs)
        return readArgs;
    }
    public getUsedVars(): Set<string>{
        let allArgs = new Set<string>();
        for (let i = 0; i <= this.nParam; i++)
            allArgs.add("x" + i);
        for (let s of this.statements)
            s.addArgs(allArgs)
        return allArgs;
    }
    public IsWhile(): boolean{
        if (this.statements) {
            for (let ls of this.statements) {
                if (ls.UsesWhile())
                    return true;
            }
        }
        return false;
    }
}

export class LoopStatement{
    required?: boolean;
    constructor() {
    }
    public toString(): string { // Die vollständige Funktionsdefinition
        throw new Error("Method not implemented.");
    }
    public toIndented(pre:string): string { // Definition mit Zeilenumbrüchen und Einrückung
        throw new Error("Method not implemented.");
    }
    addDependencies(r: Set<Loop>, deep: boolean) {
        throw new Error("Method not implemented.");
    }
    addReadArgs(readArgs: Set<string>, writeArgs: Set<string>) {
        throw new Error("Method not implemented.");
    }
    addArgs(allArgs: Set<string>) {
        throw new Error("Method not implemented.");
    }
    UsesWhile():boolean {
        throw new Error("Method not implemented.");
    }
}

export class StmLoop extends LoopStatement { // LOOP loopVar DO statements END
    loopVar: string;
    statements: Array<LoopStatement>;
    constructor(loopVar:string, statements:Array<LoopStatement>) {
        super();
        this.loopVar = loopVar;
        this.statements = statements;
    }
    public toString(): string { // Die vollständige Funktionsdefinition
        return "LOOP " + this.loopVar + " DO " + this.statements.map(s=>s.toString()).join(";") + " END";
    }
    public toIndented(pre: string): string { // Definition mit Zeilenumbrüchen und Einrückung
        return pre + "LOOP " + this.loopVar + " DO\n" + this.statements.map(s => s.toIndented(pre + "\t")).join(";\n") + "\n" + pre + "END";
    }
    
    addDependencies(r: Set<Loop>, deep: boolean): any {
        for (let d of this.statements)
            d.addDependencies(r, deep);
        return r;
    }
    addReadArgs(readArgs: Set<string>, writeArgs: Set<string>) {
        if (!writeArgs.has(this.loopVar))
            readArgs.add(this.loopVar);
        for (let s of this.statements) {
            s.addReadArgs(readArgs, writeArgs);
        }
    }
    addArgs(allArgs: Set<string>) {
        allArgs.add(this.loopVar);
        for (let s of this.statements) {
            s.addArgs(allArgs);
        }
    }
    UsesWhile() {
        for (let ls of this.statements) {
            if (ls.UsesWhile())
                return true;
        }
        return false;
    }
}

export class StmCall extends LoopStatement { // targetVar := func(param)
    targetVar: string;
    func: Loop;
    param: Array<string | number>;
    i: number; // Konkreter Index der Funktion
    constructor(targetVar: string, func: Loop, param: Array<string|number>, i:number) {
        super();
        this.targetVar = targetVar;
        this.func = func;
        this.param = param;
        this.i = i;
    }
    public toString(): string { // Die vollständige Funktionsdefinition
        return this.targetVar + " := " + this.func.name+(this.i === undefined ? "" : "_"+this.i)+"("+this.param.join(",")+")";
    }
    public toIndented(pre: string): string { // Definition mit Zeilenumbrüchen und Einrückung
        return pre + this.toString();
    }
    
    addDependencies(r: Set<Loop>, deep: boolean): any {
        if (!r.has(this.func)) {
            r.add(this.func);
            if (deep)
                this.func.addDependencies(r, deep);
        }
        return r;
    }
    addReadArgs(readArgs: Set<string>, writeArgs: Set<string>) {
        for (let p of this.param) {
            if (typeof(p) != "number" && !writeArgs.has(p))
                readArgs.add(p);
        }
        writeArgs.add(this.targetVar);
    }
    addArgs(allArgs: Set<string>) {
        allArgs.add(this.targetVar);
        for (let p of this.param) {
            if (typeof (p) != "number")
                allArgs.add(p);
        }
    }
    UsesWhile() {
        return this.func.IsWhile();
    }
}

export class StmAssign extends LoopStatement { // targetVar := sourceVar
    targetVar: string;
    sourceVar: string|number; // Variable oder Konstante
    constructor(targetVar: string, sourceVar: string|number) {
        super();
        this.targetVar = targetVar;
        this.sourceVar = sourceVar;
    }
    public toString(): string { // Die vollständige Funktionsdefinition
        return this.targetVar + " := " + this.sourceVar;
    }
    public toIndented(pre: string): string { // Definition mit Zeilenumbrüchen und Einrückung
        return pre + this.toString();
    }
    
    addDependencies(r: Set<Loop>, deep: boolean): any {
        return r;
    }
    addReadArgs(readArgs: Set<string>, writeArgs: Set<string>) {
        if (typeof(this.sourceVar) != "number" && !writeArgs.has(this.sourceVar))
            readArgs.add(this.sourceVar);
        writeArgs.add(this.targetVar);
    }
    addArgs(allArgs: Set<string>) {
        if (typeof (this.sourceVar) != "number")
            allArgs.add(this.sourceVar);
        allArgs.add(this.targetVar);
    }
    UsesWhile() {
        return false;
    }
}

export class StmWhile extends StmLoop{
    constructor(whileVar: string, statements: Array<LoopStatement>) {
        super(whileVar, statements);
    }
    public toString(): string { // Die vollständige Funktionsdefinition
        return "WHILE " + this.loopVar + " DO " + this.statements.map(s => s.toString()).join(";") + " END";
    }
    public toIndented(pre: string): string { // Definition mit Zeilenumbrüchen und Einrückung
        return pre + "WHILE " + this.loopVar + " DO\n" + this.statements.map(s => s.toIndented(pre + "\t")).join(";\n") + "\n" + pre + "END";
    }
    UsesWhile() {
        return true;
    }
}
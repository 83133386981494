import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmData {
  message: string;
  title: string;
  cancelText: string;
  okText: string;
}

@Component({
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent {
  public message: string;
  public title: string;
  public cancelText: string;
  public okText: string;
  constructor(public dialogRef: MatDialogRef<ConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data:ConfirmData) {
    this.message = data.message;
    this.title = data.title;
    this.cancelText = data.cancelText;
    this.okText = data.okText;
  }
}

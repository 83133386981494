import { Component, OnInit, Input, Inject } from '@angular/core';
import { PRFGeneratorService } from "../prfgenerator.service"
import { PRF } from "../prf"
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PRFFormattingService } from '../prfformatting.service';

export interface GeneratorData {
    name: string;
    parans: number;
}

@Component({
    templateUrl: './generator.component.html',
    styleUrls: ['./generator.component.css']
})
// Komponente zur Anzeige generierter Funktionen
export class PRFGeneratorComponent implements OnInit {
    generatedSub: IterableIterator<PRF>
    generatedRec: IterableIterator<PRF>
    substitutions: Array<PRF>
    recursions: Array<PRF>
    showNextSub: boolean = true;
    showNextRec: boolean = true;
    n: number
    sampleprf: PRF;
    private isVisible: boolean = false;
    constructor(private generator: PRFGeneratorService, private fmt:PRFFormattingService,
        public dialogRef: MatDialogRef<PRFGeneratorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: GeneratorData) {
    }

    ngOnInit() {
        this.refresh();
    }
    refresh() {
        this.n = 1;
        this.generatedSub = this.generator.generateCompositions(this.n);
        this.generatedRec = this.generator.generateRecursions()
        this.substitutions = []
        this.recursions = []
        this.nextSub();
        this.nextRec();
    }
    nextSub() {
        for (let i = 0; i < 20; i++) {
            let r = this.generatedSub.next()
            if (r.done) {
                if (!this.n)
                    this.showNextSub = false;
                else {
                    this.n++;
                    this.generatedSub = this.generator.generateCompositions(this.n);
                }
                break;
            }
            this.substitutions.push(r.value)
        }
    }
    nextRec() {
        for (let i = 0; i < 20; i++) {
            let r = this.generatedRec.next()
            if (r.done) {
                this.showNextRec = false;
                break;
            }
            this.recursions.push(r.value)
        }
    }
    onSelect(f: PRF) {
        this.sampleprf = f;
    }
}

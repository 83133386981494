import { Injectable, Output, EventEmitter } from "@angular/core";
import { PRF, PRFType } from "./prf";
import { DatabaseService } from "../general/database.service";
import { PRFTokenizer } from "./prfformatting.service";
import { PRFParsingService } from "./prfparsing.service";
import * as CodeMirror from "codemirror";


export class EditPRF{ // Repräsentiert eine Funktion in Bearbeitung
    original: PRF; // Das gespeicherte Original
    currentName: string; // Der aktuelle Name
    currentComment: string; // Der aktuelle Kommentar
    currentCode: string; // Der aktuelle Funktionstext
    currentHelper: Array<EditPRF>; // Aktuelle Hilfsfunktionen
    currentPRF: PRF; // Momentane Ergebnisfunktion
    message: string; // Code des Hilfetexts zum aktuellen Zustand der Definition
    messageParam: object; // Parameter zum Hilfetext
    msgType: string; // Art des Hilfetexts: "info", "warning", "error"
    canSave: boolean; // Kann die Funktion momentan gespeichert werden?
    editID: string; // ID der Bearbeitung
    constructor(orig: PRF, name: string = "") {
        this.editID = Math.random().toString(36).substring(2);
        this.original = orig ? orig : undefined;
        this.currentName = orig ? orig.name : name;
        this.currentComment = orig ? orig.comment : "";
        this.currentCode = orig ? orig.toString() : "";
        this.currentHelper = orig && orig.helper ? orig.helper.map(h => new EditPRF(h)) : [];
        this.currentPRF = undefined;
        this.message = " ";
        this.msgType = "info"
        this.canSave = false;
    }
    
    addHelper(index:number) {
        this.currentHelper.splice(index, 0, new EditPRF(undefined, ""));
    }
    removeHelper(index: number) {
        this.currentHelper[index].unregTokenizer();
        this.currentHelper.splice(index, 1);
    }
    unregTokenizer() {
        CodeMirror.defineMode(this.editID, undefined);
        this.currentHelper.forEach(h => h.unregTokenizer());
    }
    isBasic(): boolean{
        return this.original ? this.original.isBasic() : false;
    }
    hasChanged(): boolean{
        if (this.original) {
            return !this.currentPRF || this.original.toString() != this.currentPRF.toString() ||
                this.original.comment != this.currentComment ||
                this.original.helper.reduce((r, h) => r + h.toString(), "") != this.currentPRF.helper.reduce((r, h) => r + h.toString(), "") ||
                this.original.helper.reduce((r, h) => r + h.comment ? "\n" + h.comment : "", "") != this.currentPRF.helper.reduce((r, h) => r + h.comment ? "\n" + h.comment : "", "");
        }
        return this.currentName != "";
    }
    update(basefunctions: Array<PRF>, parser: PRFParsingService, db:DatabaseService): PRF { // Funktionsdefinition wurde verändert
        try {
            this.message = " "; // Message zurücksetzen
            this.currentPRF = undefined;
            let helper: Array<PRF> = []
            let useableFunctions = basefunctions.slice(); // Kopie der zu nutzenden Basisfunktionenliste (wegen Injection)
            for (let h of this.currentHelper.slice().reverse()) { // Hilfsfunktionen überprüfen
                let result = h.update(useableFunctions, parser, db)
                if (result) { // Gültige Hilfsfunktion
                    result.type = PRFType.HELP;
                    h.original = result;
                    helper.push(result);
                    useableFunctions.push(result); // im weiteren Verlauf nutzbar
                }
            }
            CodeMirror.defineMode(this.editID, () => new PRFTokenizer(() => this.original ? useableFunctions.concat([this.original]) : useableFunctions));
            if (this.isBasic())
                this.currentPRF = this.original;
            else {
                this.currentPRF = parser.parsePRF(this.currentCode, useableFunctions); // Definition prüfen
                this.currentPRF.comment = this.currentComment;
                this.currentPRF.helper = helper.reverse();
                this.currentName = this.currentPRF.name; // Funktionsnamen aktualisieren
            }
            if (this.currentPRF.isBasic()) { // Parsing ergab eine Basisfunktion
                this.message = "BASEFUNCTION";
                this.messageParam = undefined;
                this.canSave = false; // Basisfunktionen können niemals verändert werden
                this.msgType = this.original && this.original.isBasic() ? "info" : "error"; // Hinweis oder Fehler (wenn Basisfunktion neu definiert werden soll)
            }
            else if (this.currentPRF.type != PRFType.NEW) { // Parsing ergab eine bereits gespeicherte User-Funktion
                this.message = "FUNCEXISTSALREADY";
                this.messageParam = { fname: this.currentPRF.name }
                this.canSave = false; // Kann nicht gespeichert werden
                this.msgType = "error";
            }
            else {
                if (this.original) {
                    let [min, max] = this.currentPRF.getMinMaxParam();
                    let [omin, omax] = this.original.getMinMaxParam();
                    if ((min > omin || max < omax) && db.usedByPRF(this.original).size) {
                        this.message = "PARAMETERSCHANGED";
                        this.messageParam = { fname: this.currentPRF.name }
                        this.msgType = "error";
                        this.canSave = false;
                        return undefined;
                    }
                }
                this.message = "PRFCORRECT";
                this.messageParam = { fname: this.currentPRF.name }
                this.msgType = "info";
                this.canSave = this.hasChanged();
                return this.currentPRF;
            }
        }
        catch (err) {
            this.message = err.msg;
            this.messageParam = err.msgParam;
            this.msgType = "error"
            this.canSave = false;
        }
        return undefined;
    }
}

@Injectable()
export class PRFEditingService {
    @Output() editing: EventEmitter<number> = new EventEmitter();
    theFunctions: PRF[];
    public currentIndex: number;
    public editFunctions: Array<EditPRF>;
    constructor(private db: DatabaseService, private parser:PRFParsingService) {
        this.theFunctions = db.functions;
        this.editFunctions = [];
        this.currentIndex = -1;
    }
    update(f:EditPRF) {
        let basefunctions = new Set(this.db.functions);
        let ignored = [];
        let helper = this.db.functions.reduce((h, f) => f.helper ? h.concat(f.helper) : h,[])
        if (f.original) {
            for (let d of this.db.usedByPRF(f.original)) {
                basefunctions.delete(d);
                ignored.push(d);
            }
            basefunctions.delete(f.original);
        }
        let resPRF = f.update(Array.from(basefunctions), this.parser, this.db);
        if (resPRF) {
            if (ignored.find(i => i.name == resPRF.name)) {
                f.message = "FUNCEXISTSALREADY";
                f.messageParam = { fname: resPRF.name }
                f.canSave = false; // Kann nicht gespeichert werden
                f.msgType = "error";
            }
            if (helper.find(i => i.name == resPRF.name)) {
                f.message = "NAMEUSEDASHELPER";
                f.messageParam = { fname: resPRF.name }
                f.canSave = false; // Kann nicht gespeichert werden
                f.msgType = "error";
            }
        }
    }
    create(name, template:PRF=undefined) { // Eine neue Funktion wird erzeugt
        this.currentIndex = this.editFunctions.push(new EditPRF(template, name)) - 1;
        if (template) { // Bei NEU auf Basis einer Vorlage gibt es keine Originale
            this.editFunctions[this.currentIndex].original = undefined;
            for (let h of this.editFunctions[this.currentIndex].currentHelper)
                h.original = undefined;
        }
        this.update(this.editFunctions[this.currentIndex]);
    }
    edit(f: PRF) { // Ein Original wird bearbeitet
        this.currentIndex = this.editFunctions.findIndex(ef=>ef.original == f);
        if (this.currentIndex == -1) // Es gibt noch keinen offenen Tab der aktiviert werden könnte
            this.currentIndex = this.editFunctions.push(new EditPRF(f)) - 1;
        this.update(this.editFunctions[this.currentIndex]);
    }
    editIndex(i: number) { // Ein Tab wird aktiviert
        if (i < 0) return;
        this.currentIndex = i;
        this.update(this.editFunctions[this.currentIndex]);
    }
    stopEditSet(fns: Set<PRF>) { // Eine ganze Menge von Original-Funktionen wird nicht mehr bearbeitet
        for (let i = this.editFunctions.length - 1; i >= 0; i--) { // Tabs von hinten nach vorn
            if (fns.has(this.editFunctions[i].original)) { // ein zu schließender Tab
                this.editFunctions[i].unregTokenizer();
                this.editFunctions.splice(i, 1); // Tab entfernen
                if (i <= this.currentIndex) // befand sich vor dem aktiven Tab oder war es selbst
                    this.currentIndex--; // aktiver Tab hat einen kleineren Index
            }
        }
        if (this.currentIndex >= 0)
            this.update(this.editFunctions[this.currentIndex]);
    }
    stopEdit(f: EditPRF) { // Ein Tab wird geschlossen
        let i = this.editFunctions.indexOf(f); // Index des zu schließenden Tabs
        this.editFunctions[i].unregTokenizer();
        this.editFunctions.splice(i, 1); // Tab entfernen
        if (i <= this.currentIndex) // befand sich vor dem aktiven Tab oder war es selbst
            this.currentIndex--; // aktiver Tab hat einen kleineren Index
        if (this.currentIndex >= 0)
            this.update(this.editFunctions[this.currentIndex]);
    }
    addHelper(f: EditPRF, index: number) {
        f.addHelper(index);
        this.update(f);
    }
    removeHelper(f: EditPRF, index: number) {
        f.removeHelper(index);
        this.update(f);
    }
    saveChanges(f: EditPRF) {
        if (!f.canSave) return;
        if (!f.original) {
            f.original = f.currentPRF;
            this.db.addPRF(f.original);
        }
        else {
            f.original.name = f.currentPRF.name;
            f.original.comment = f.currentPRF.comment;
            f.original.index = f.currentPRF.index;
            f.original.param = f.currentPRF.param;
            f.original.definition = f.currentPRF.definition;
            f.original.helper = f.currentPRF.helper;
            f.original.type = f.currentPRF.isMu() ? PRFType.USERMU : PRFType.USERPRIM;
            this.db.savePRF();
            for (let uprf of this.db.usedByPRF(f.original, true)) {
                if (!uprf.isBasic())
                    uprf.type = uprf.isMu() ? PRFType.USERMU : PRFType.USERPRIM;
            }
        }
        this.update(f);
    }
}
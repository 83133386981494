import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
// Komponente zur Anzeige der aktuellen Funktionsbasis
export class HomeComponent implements OnInit {
    supportsBigInt:boolean = false
    constructor() {
        try {
            let n = new Function("let x = 1n;");
            this.supportsBigInt = true;
        }
        catch (err) {
            this.supportsBigInt = false;
        }
    }

    ngOnInit() {
        
    }
    
}

import { Injectable } from "@angular/core";
import { PRF, Equation, VARARGS, Func, PRFType, FuncMu } from "./prf";
import { CoMoSimError } from "../general/comosimerror";

function isVariable(v: string) { return v == VARARGS || v == "#" || v.search(/^[a-zA-Z][a-zA-Z0-9]*$/) == 0; }

@Injectable()
export class PRFParsingService {
    constructor() {
    }
    // Extrahiert name_index(parameterliste)
    private parseFunction(str: string, allowMu:boolean): [string, string | number, Array<string>,boolean] {
        str = str.replace(/\s/g, ""); // Leerzeichen entfernen
        let m = str.match(/^(\w[\w|\.]*)\((.*)\)$/) // abc123_abc123(...)
        let isMu = false;
        if (!m) {
            if (allowMu) // Mu [f(#,x1,...,xk)]
                m = str.match(/^\[(\w[\w|\.]*)\((.*)\)\]$/)
            if (!m)
                throw new CoMoSimError("INVALIDCALL", { call: str }); // Kein Funktionsaufruf
            else
                isMu = true;
        }
        if (m.length != 3) throw new CoMoSimError("SYNTAXERROR", { call: str });
        let n = "", i = undefined; // Name und Index
        if (m[1].indexOf("_") == -1)
            n = m[1] // Es gibt keinen Index
        else if ((m[1].match(/_/g) || []).length == 1)
            [n, i] = m[1].split("_") // Es gibt genau einen Index
        else
            throw new CoMoSimError("MULTIPLEUNDERSCORE", { call: str }); // Es gibt mehr als einen _
        let ni = parseInt(i, 10);
        if (!isNaN(ni) && i.match(/^(0|[1-9]\d*)$/))
            i = ni; // Index ist eine konkrete Zahl
        let params = [] // Parameter identifizieren
        let level = 0;
        let p = undefined
        for (let c of m[2]) {
            if (c == "(")
                level++;
            else if (c == ")")
                level--;
            else if (c == "," && level == 0) {
                if (p == undefined || p === "")
                    throw new CoMoSimError("MISSINGPARAMETER", { call: str }); // Parameter nicht definiert
                if (p == VARARGS)
                    throw new CoMoSimError("VARARGSATENDONLY", { call: str }); // * nur als letzter Parameter erlaubt
                params.push(p)
                p = ""
                continue;
            }
            p = p == undefined ? c : p + c;
        }
        if (p != undefined) {
            if (p.length > 0) {
                params.push(p);
            }
            else
                throw new CoMoSimError("MISSINGPARAMETER", { call: str });
        }
        return [n, i, params,isMu];
    }
    public parsePRF(s: string, all: Array<PRF>): PRF { // Parsen einer Funktionsdefinition
        if (!s) throw new CoMoSimError("NOFUNCTIONDEF");
        let eq = s.split("\n").map(l => l.replace(/\s/g, "")); // Leerzeichen entfernen und Zeilen extrahieren
        let [name, index, params,isMu] = this.parseFunction(eq[0].split("=")[0],false) // aus der ersten Zeile vor = die Basisinfos holen
        let existing = all.find(pr => pr.name == name); // Gibt es diese Funktion schon
        let f = new PRF(existing ? existing.type : PRFType.NEW, name, index, params, "");
        if (!existing && index != undefined)
            throw new CoMoSimError("INDEXNOTFOROWN");
        if (!f.isBasic()) { // Außer bei Basisfunktionen Definition parsen
            f.definition = eq.map(e => this.parseEquation(e, all.concat([f])));
            if (f.definition.length == 2) {
                let sF = f.definition[1].lf.params[0] as Func;
                f.param[0] = sF.params[0].toString();
            }
            this.checkPRFSchema(f);
        }
        return f;
    }
    public deserializePRF(o, all: Array<PRF>): PRF { // PRF aus o generieren, "all" sind die bereits bestehenden Funktionen
        if (o == undefined || o.definition == undefined || !Array.isArray(o.definition)) throw new CoMoSimError("INVALIDSTORAGEFORMAT", { element: "definition" });
        let funcbase = all.slice(); // Alle momentan verfügbaren Hauptfunktionen dürfen als Basis verwendet werden
        let helper: Array<PRF> = [];
        if (o.helper) { // Wenn es Hilfsfunktionen gibt
            if (!(o.helper instanceof Array)) throw new CoMoSimError("INVALIDSTORAGEFORMAT", { element: "helper" }); // Definition als Array erforderlich
            for (let h of o.helper.slice().reverse()) { // In Reihenfolge der Abhängigkeit deserialisieren
                let hprf = this.deserializePRF(h, funcbase); // Hilfsfunktion rekursiv deserialisieren
                hprf.type = PRFType.HELP; // Typ festlegen
                helper.push(hprf); // merken
                funcbase.push(hprf); // Erweitert die Funktionsbasis für weitere Helper und die Hauptfunktion
            }
        }
        let f = this.parsePRF(o.definition.join("\n"), funcbase); // Hauptfunktion darf alle Helper verwenden
        if (f.type != PRFType.NEW) throw new CoMoSimError("FUNCEXISTSALREADY", {fname:f.name});
        f.comment = o.comment; // Kommentar übernehmen
        f.helper = helper.reverse(); // Hilfsfunktionen übernehmen
        f.type = f.isMu() ? PRFType.USERMU : PRFType.USERPRIM; // Typ festlegen
        return f;
    }
    private checkPRFSchema(f:PRF) {
        if (f.isBasic()) return;
        if (f.definition.length == 1) { // einzeilige Definition
            let lf = f.definition[0].lf;
            let rf = f.definition[0].rf;
            if (rf instanceof (FuncMu)) {
                if (lf.params.find(p => p instanceof (Func)) || //linksseitige Funktionsaufrufe
                    rf.f == f || // Rekursiver Aufruf
                    rf.params.indexOf("#") == -1 || // Kein Mu-Parameter
                    rf.params.find(rp=>rp instanceof(Func))) // rechts verschachtelte Funktionsaufrufe
                    throw new CoMoSimError("NOTMUSCHEMA");
                
                let args = lf.params.toString(); // Parameter der linken Seite
                let rargs = rf.params.filter(pn => pn != "#").toString(); // Parameter der rechten Seite ohne #
                if (args != rargs) // Parameterlisten nicht identisch
                    throw new CoMoSimError("NOTMUSCHEMA");
            }
            else {
                if (lf.params.find(p => p instanceof (Func)) != undefined)
                    throw new CoMoSimError("NOTSUBSTITUTIONSCHEMA"); //linksseitig keine Funktionsaufrufe
                if (rf.f == f)
                    throw new CoMoSimError("NOTSUBSTITUTIONSCHEMA"); //kein rekursiver Aufruf
                let args = lf.params.toString(); // Parameter der linken Seite
                for (let p of rf.params) {
                    if (!(p instanceof (Func)) || p.params.toString() != args || p.f == f)
                        throw new CoMoSimError("NOTSUBSTITUTIONSCHEMA");
                }
            }
        }
        else { // mehrzeilige Definition
            if (f.definition.length != 2) throw new CoMoSimError("NOTRECURSIONSCHEMA");//zweizeilige Definition erforderlich
            let lf = f.definition[0].lf;
            let rf = f.definition[0].rf;
            // if (rf.f == f)
            //     throw new CoMoSimError("NOTRECURSIONSCHEMA"); //kein rekursiver Aufruf beim Elementarfall
            let fp = lf.params.filter(p => p instanceof (Func)) as Array<Func>;
            let args = lf.params.slice(1);
            if (fp.length != 1 || // Genau eine Funktion als Parameter
                fp[0].f.type !== PRFType.CONS || // Genau einmal die Null-Funktion als Parameter
                rf.params.toString() != args.toString()) // Die übrigen Parameter entsprechen nicht der rechten Seite
                throw new CoMoSimError("NOTRECURSIONSCHEMA"); // für den Elementarfall, erste Zeile der Gleichung
            lf = f.definition[1].lf;
            rf = f.definition[1].rf;
            if (rf.f == f)
                throw new CoMoSimError("NOTRECURSIONSCHEMA"); //kein rekursiver Aufruf als äußere Funktion im Rekursionsfall
            let fp2 = lf.params.filter(p => p instanceof (Func)) as Array<Func>;
            if (lf.params.slice(1).toString() != args.toString() || // Die übrigen Parameter entsprechen nicht der ersten Zeile
                fp2.length != 1 || // Nicht genau eine Funktion als Parameter
                fp2[0].f.type !== PRFType.SUCC) // Nicht die Nachfolger-Funktion als Parameter
                throw new CoMoSimError("NOTRECURSIONSCHEMA"); // für den Rekursionsfall"
            let rp = fp2[0].params[0];
            if (rf.params.length != 2 + (args.length) || // Anzahl der Parameter auf der rechten Seite falsch
                rf.params[0] != rp || // Der erste Parameter ist nicht die Rekursionsvariable
                rf.params.slice(2).toString() != args.toString() || // Parameter am Ende entsprechen nicht der Vorgabe
                !(rf.params[1] instanceof (Func)) || // Der zweite Parameter ist keine Funktion
                (rf.params[1] as Func).f != f) // Die Funktion an zweiter Stelle ist nicht die definierte Funktion
                throw new CoMoSimError("NOTRECURSIONSCHEMA");
            let fn = rf.params[1] as Func;
            if (fn.params[0] != rp || // Der erste Parameter des Rekursionsaufrufs ist nicht die Rekursionsvariable
                fn.params.slice(1).toString() != args.toString()) // Die übrigen Parameter entsprechen nicht der Definition
                throw new CoMoSimError("NOTRECURSIONSCHEMA"); // falscher Rekursionsaufruf"
        }
    }
    
    private deserializeFunc(o: string,allowMu:boolean,all: Array<PRF>): Func { // Funktionsaufruf parsen
        let [name, index, params, isMu] = this.parseFunction(o, allowMu) // Die 3 Bestandteile
        let cf = all.find(pr => pr.name == name); // Aufgerufene Funktion ermitteln
        if (!cf) throw new CoMoSimError("UNKNOWNFUNCTION", { fname: name });
        if (cf.index) { // Index definiert
            if (index == undefined) throw new CoMoSimError("MISSINGINDEX", { call: o }); // kein Index angegeben
            if (typeof (index) != "number") throw new CoMoSimError("VARIABLEINDEXNOTALLOWED", { call: o }); // kein fester Index angegeben
            if (cf.type === PRFType.PROJ) { // Bei der Projektionsfunktion
                if (index == 0 || (params.indexOf(VARARGS) > -1 ? params.length <= index : params.length < index))
                    throw new CoMoSimError("INVALIDINDEX", { call: o }); // Der Index ist zu groß oder zu klein
            }
        }
        else // Kein Index definiert
            if (index != undefined) throw new CoMoSimError("NOINDEXEXPECTED", { call: o }) // trotzdem einer angegeben
        
        let rparams = [] // Aufrufparameter übernehmen
        for (let p of cf.param) { // Die definierten Parameter der Funktion
            if (p == VARARGS) { // Alle weiteren Parameter sind zulässig
                rparams = rparams.concat(params);
                params = [] // keine übrigen Parameter
                break;
            }
            else if (params.length > 0 && params[0] != VARARGS) // Es gibt noch Werte für den Parameter
                rparams.push(params.shift());
            else // Für einen erforderliche Parameter gibt es keinen Wert im Aufruf
                throw new CoMoSimError("REQUIREDPARAMETERMISSING", { call: o });
        }
        if (params.length > 0) // Es wurden mehr Werte angegeben als es Parameter gibt
            throw new CoMoSimError("TOOMANYPARAM", { call: o })
        // Ergebnis wobei Parameterwerte die selbst Funktionsaufrufe sind rekursiv geparst werden
        if (isMu)
            return new FuncMu(cf, index, rparams.map(rp => isVariable(rp) ? rp : this.deserializeFunc(rp, false, all)));    
        return new Func(cf, index, rparams.map(rp => isVariable(rp) ? rp : this.deserializeFunc(rp, false, all)));
    }
    private getFuncVariables(func:Func, noDoubles: boolean = false, p: Set<string> = new Set<string>(), i: Set<string> = new Set<string>()): [Set<string>, Set<string>] {
        if (typeof (func.i) == "string")
            i.add(func.i);
        for (let param of func.params) {
            if (param instanceof (Func))
                this.getFuncVariables(param,noDoubles, p, i);
            else {
                if (noDoubles && p.has(param))
                    throw new CoMoSimError("MULTIUSAGEOFPARAM", { param: param });
                p.add(param);
            }
        }
        return [p, i];
    }
    private parseEquation(s: string, all: Array<PRF>):Equation {
        let eq = s.split("=");
        if (eq.length != 2 || eq[0] == "" || eq[1] == "") throw new CoMoSimError("NOEQUATION", { text: s });
        let lf = this.deserializeFunc(eq[0], false, all);
        let rf = this.deserializeFunc(eq[1], true, all);
        let [pl, il] = this.getFuncVariables(lf,true);
        let [pr, ir] = this.getFuncVariables(rf);
        for (let p of pr) { // Variablen auf der rechten Seite müssen links als Argument definiert sein
            if (p == "#") continue;
            if (!pl.has(p)) throw new CoMoSimError("UNDEFINEDPARAMETER", { param: p });
        }
        for (let p of pl) {
            if (all.find(fa => fa.name == p)) {
                throw new CoMoSimError("VARASFUNCTION", { var: p });
            }
        }
        return new Equation(lf, rf);
    }
}



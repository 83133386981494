<div style="display:flex;height:66px">
    <h1 mat-dialog-title translate>GENERATOR</h1>
    <span style="flex:1"></span>
    <p class="mono" style="font-size:18px;margin-top:0" *ngIf="sampleprf" [innerHTML]="fmt.colorizeFormula(sampleprf.toString()).replace('\n','<br>') | safeHtml"></p>
</div>
<div mat-dialog-content style="display:flex;flex-direction:row">
    <div style="display:flex;flex:1" id="generatortab">
        <mat-tab-group mat-stretch-tabs style="min-width:500px;flex:1">
            <mat-tab label="{{'SUBSTITUTIONS' | translate}}">
                <mat-nav-list style="flex:1;display:flex;flex-direction:column;">
                    <mat-list-item *ngFor="let f of substitutions" (click)="onSelect(f)" [ngClass]="{'active-item': sampleprf == f}">
                        <p matLine class="mono" style="font-size:18px" *ngFor="let line of fmt.colorizeFormula(f.toString()).split('\n')" [innerHTML]="line | safeHtml"> </p>
                        <mat-divider></mat-divider>
                    </mat-list-item>
                    <mat-list-item *ngIf="showNextSub" (click)="nextSub()">
                        <p matLine style="font-size:18px"translate> MORE </p>
                        <mat-divider></mat-divider>
                    </mat-list-item>
                </mat-nav-list>
            </mat-tab>
            <mat-tab label="{{'PRECURSIONS' | translate}}">
                <mat-nav-list style="flex:1;display:flex;flex-direction:column;">
                    <mat-list-item *ngFor="let f of recursions" (click)="onSelect(f)" [ngClass]="{'active-item': sampleprf == f}">
                        <p matLine class="mono" style="font-size:18px" *ngFor="let line of fmt.colorizeFormula(f.toString()).split('\n')" [innerHTML]="line | safeHtml"> </p>
                        <mat-divider></mat-divider>
                    </mat-list-item>
                    <mat-list-item *ngIf="showNextRec" (click)="nextRec()">
                        <p matLine style="font-size:18px" translate> MORE </p>
                        <mat-divider></mat-divider>
                    </mat-list-item>
                </mat-nav-list>
            </mat-tab>
        </mat-tab-group>
        <mat-divider [vertical]="true"></mat-divider>
        <prf-eval style="display:flex;flex-direction:column;min-width:250px" [sampleprf]="sampleprf"></prf-eval>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="dialogRef.close(false)">
        <span translate>CANCEL</span>
    </button>
    <span style="flex:1"></span>
    <button mat-raised-button cdkFocusInitial color="primary" [mat-dialog-close]="sampleprf" [disabled]="!sampleprf">
        <span translate>OK</span>
    </button>
</div>


import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { PRFEditingService, EditPRF } from '../prfediting.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmComponent } from '../../general/confirm/confirm.component';
import { PRFEditorComponent } from '../prfeditor/prfeditor.component';
import { DatabaseService } from '../../general/database.service';
import { ErrorService } from '../../general/error.service';


@Component({
    selector: 'prfview',
    templateUrl: './prfview.component.html',
    styleUrls: ['./prfview.component.css']
})
// Komponente zur Anzeige der PRF-Umgebung
export class PRFViewComponent implements OnInit {
    @ViewChild('tabs', { static: true }) tabs: MatTabGroup; // Das Tab-Control
    @ViewChildren('editor') editors: QueryList<PRFEditorComponent>; // Das Tab-Control
    constructor(private errsvc: ErrorService,
        public es: PRFEditingService,
        private translate: TranslateService,
        private db: DatabaseService,
        public dialog: MatDialog) {
    }

    ngOnInit() {
        // Überwachung der manuellen Tab-Auswahl starten
        this.tabs.selectedIndexChange.subscribe(index => {
            // Dem EditingService Bescheid geben, dass eine andere Funktion gewählt wurde
            this.es.editIndex(index);
            if (index >= 0)
                this.editors.toArray()[index].update();
        })
    }
    close(f: EditPRF) { // Tab soll geschlossen werden
        if (f.hasChanged()) { // Wenn es bereits Änderungen an der Funktion gibt
            this.dialog.open(ConfirmComponent, { // Schließen bestätigen
                data: {
                    title: this.translate.instant("CLOSETAB"),
                    message: this.translate.instant("REALLYCLOSE", { fname: f.currentName }),
                    cancelText: this.translate.instant("DONTCLOSE"),
                    okText: this.translate.instant("YESCLOSE")
                }
            }).afterClosed().subscribe(confirmed => {
                if (confirmed)
                    this.es.stopEdit(f);
            });
        }
        else
            this.es.stopEdit(f);
    }
    onNewFunction() { // Button: Neue Funktion
        this.es.create(this.translate.instant("NEW"));
    }
}

import { Injectable } from "@angular/core";
import { Loop, LoopType, LoopStatement, StmLoop, StmCall, StmAssign, StmWhile } from "./loop";
import { CoMoSimError } from "../general/comosimerror";
var reVar: RegExp = /^x(0|[1-9]\d*)$/
var reNum: RegExp = /^(0|[1-9]\d*)$/
@Injectable()
export class LoopParsingService {
    constructor() {
    }
    // Extrahiert name_index(parameterliste)
    private parseFunction(str: string): [string, number, Array<string>] {
        if (!str) throw new CoMoSimError("NOFUNCTIONDEF");
        let m = str.match(/^(\w[\w|\.]*)\((.*)\)$/) // abc.123_abc123(...)
        if (!m) throw new CoMoSimError("INVALIDCALL", { call: str }); // Kein Funktionsaufruf
        if (m.length != 3) throw new CoMoSimError("SYNTAXERROR", { call: str });
        let n = "", i = undefined; // Name und Index
        if (m[1].indexOf("_") == -1)
            n = m[1] // Es gibt keinen Index
        else if ((m[1].match(/_/g) || []).length == 1)
            [n, i] = m[1].split("_") // Es gibt genau einen Index
        else
            throw new CoMoSimError("MULTIPLEUNDERSCORE", { call: str }); // Es gibt mehr als einen _
        if (!n.match(/^[a-zA-Z][a-zA-Z0-9\.]*$/)) throw new CoMoSimError("INVALIDFUNCTIONNAME", { fname: n });
        if (n.match(/^(LOOP|END|DO|WHILE|x(0|[1-9]\d*))$/)) throw new CoMoSimError("INVALIDFUNCTIONNAME", { fname: n });
        if (i !== undefined) {
            let ni = parseInt(i, 10);
            if (!isNaN(ni) && i.match(/^(0|[1-9]\d*)$/))
                i = ni; // Index ist eine konkrete Zahl
            else
                throw new CoMoSimError("INVALIDINDEX", { call: m[1] });
        }
        let params = [] // Parameter identifizieren
        let level = 0;
        let p = undefined
        for (let c of m[2]) {
            if (c == "(")
                level++;
            else if (c == ")")
                level--;
            else if (c == "," && level == 0) {
                if (p == undefined || p.trim() === "")
                    throw new CoMoSimError("MISSINGPARAMETER", { call: str }); // Parameter nicht definiert
                p = p.trim();
                if (!p.match(reVar)) {
                    let nVar = Number.parseInt(p);
                    if (isNaN(nVar) || !p.match(reNum))
                        throw new CoMoSimError("INVALIDVARIABLE", { var: p });
                    params.push(nVar)
                }
                else
                    params.push(p)
                p = ""
                continue;
            }
            p = p == undefined ? c : p + c;
        }
        if (p != undefined) {
            p = p.trim();
            if (p.length > 0) {
                if (!p.match(reVar)) {
                    let nVar = Number.parseInt(p);
                    if (isNaN(nVar) || !p.match(reNum))
                        throw new CoMoSimError("INVALIDVARIABLE", { var: p });
                    params.push(nVar)
                }
                else
                    params.push(p)
            }
            else
                throw new CoMoSimError("MISSINGPARAMETER", { call: str });
        }
        return [n, i, params];
    }
    
    private parseStatements(s: string, all: Array<Loop>): [Array<LoopStatement>,string]{
        let stm: Array<LoopStatement> = [];
        s = s.trim();
        while (s.length) {
            let hasSep = false; // Start mit Separator?
            while (s.length && s[0] == ";") {
                s = s.substring(1).trim(); // Entfernen
                if (hasSep) throw new CoMoSimError("UNEXPECTEDSEPARATORBEFORE", { before: ";" });  // Gibt es mehrere hintereinander?  
                hasSep = true;
            }
            let iEnd = s.search(/[\n;]/);
            let statement = s.slice(0, iEnd == -1 ? s.length : iEnd + 1).trim();
            if (hasSep && (stm.length == 0 || statement.startsWith("END") || s.length == 0)) // Keine vorherigen Statements oder es ist das letzte Statement
                throw new CoMoSimError("UNEXPECTEDSEPARATORBEFORE", { before: statement });
            if (!hasSep && stm.length && !s.startsWith("END")) // Kein Separator, es gibt bereits Statements und es ist nicht das letzte LOOP-Statement
                throw new CoMoSimError("MISSINGSEPARATORBEFORE", { before: statement });
            
            if (s.startsWith("LOOP") || s.startsWith("WHILE")) { // Ein LOOP Statement
                let whileLoop = s.startsWith("WHILE");
                let i = s.search(/\sDO/); // nächste DO finden
                if (i == -1) throw new CoMoSimError("MISSINGDOAFTERLOOP", { loop: s.slice(0, 10).replace("\n", "") + "..." });
                let loopVar = s.substring(5, i).trim(); // Loop-Variable extrahieren und prüfen
                if (!loopVar) throw new CoMoSimError("MISSINGLOOPVAR", { loop: s.slice(0, i+2).replace("\n", "") + "..." });
                if (!loopVar.match(reVar)) throw new CoMoSimError("INVALIDLOOPVAR", {loopvar:loopVar});
                s = s.slice(i + 3).trim(); // hinter dem DO gehts weiter
                let [statements, rest] = this.parseStatements(s, all); // Parsing der Statements im LOOP bis zum zugehörigen END
                if (statements.length == 0) throw new CoMoSimError("NOSTATEMENTSINLOOP", { loop: (whileLoop ? "WHILE " : "LOOP ") + loopVar + " DO" });
                if (whileLoop)
                    stm.push(new StmWhile(loopVar, statements));
                else
                    stm.push(new StmLoop(loopVar, statements));
                s = rest.trim(); // Der Rest ab dem END
                if (!s.startsWith("END")) throw new CoMoSimError("MISSINGLOOPEND", { loop: (whileLoop ? "WHILE " : "LOOP ") + loopVar + " DO"}); // Muss mit END beginnen!
                s = s.substring(3).trim(); // Hinter dem END geht es weiter
            }
            else if (s.startsWith("END")) { // Ende eines LOOPS erreicht
                return [stm, s]; // Loop-Statements und Rest inklusive "END" zurückgeben
            }
            else { // Ein Zuweisungsstatement wird erwartet
                let iSep = s.indexOf(":="); // Es muss einen Zuweisungsoperator geben
                if (iSep == -1) throw new CoMoSimError("INVALIDASSIGNMENT", { call: statement });
                let aVar = s.substring(0, iSep).trim(); // links davon ist die Zielvariable
                if (!aVar) throw new CoMoSimError("INVALIDASSIGNMENT", { call: statement });
                if (!aVar.match(reVar)) throw new CoMoSimError("INVALIDVARIABLE", { var: aVar });
                s = s.substring(iSep + 2).trim(); // Der Rest hinter dem Zuweisungsoperator
                let iVar = s.search(/^[a-zA-Z][a-zA-Z0-9\.]*(_(0|[1-9]\d*))?\(/); // Funktionsaufruf?
                if (iVar == 0) { // Muss ein Funktionsaufruf sein
                    let iEnd = s.indexOf(")");
                    if (iEnd == -1) throw new CoMoSimError("INVALIDASSIGNMENT", { call: statement });
                    let aFunc = s.substring(0, iEnd + 1);
                    let [fname, index, params] = this.parseFunction(aFunc);
                    let func = all.find(lf => lf.name == fname);
                    if (!func) throw new CoMoSimError("UNKNOWNFUNCTION", { fname: fname });
                    if (func.index === undefined && index !== undefined)
                        throw new CoMoSimError("NOINDEXEXPECTED", { fname: fname });
                    if (func.index !== undefined && index === undefined)
                        throw new CoMoSimError("MISSINGINDEX", { call: fname });
                    if (func.nParam !== params.length) {
                        if (func.nParam === undefined) { // enc-Funktion
                            if (params.length < 1)
                                throw new CoMoSimError("REQUIREDPARAMETERMISSING", { call: aFunc });
                        }
                        else if (func.nParam < params.length)
                            throw new CoMoSimError("TOOMANYPARAM", { call: aFunc });
                        else if (func.nParam > params.length)
                            throw new CoMoSimError("REQUIREDPARAMETERMISSING", { call: aFunc });
                    }
                    stm.push(new StmCall(aVar, func, params, index));
                    s = s.substring(iEnd + 1).trim();
                }
                else { // Zahl oder Variable folgt direkt
                    iVar = s.search(/[;\s]/); // nächstes Semikolon oder Whitespace
                    if (iVar == -1) iVar = s.length; // wenn keins ist, dann muss es der Rest des Texts sein
                    let sVar = s.substring(0, iVar); // Muss eine Variable oder eine Zahl sein
                    if (!sVar.match(reVar)) { // Keine Variable
                        if (sVar.match(reNum)) // Eine Zahl
                            stm.push(new StmAssign(aVar, Number.parseInt(sVar)));
                        else // Etwas anderes
                            throw new CoMoSimError("INVALIDASSIGNMENT", { call: statement });

                    }
                    else // Eine Variable
                        stm.push(new StmAssign(aVar, sVar));
                    s = s.substring(iVar).trim();
                }
            }
        }
        return [stm,s];
    }
    public parseLoop(name: string, nParam: number, comment: string, definition: string, all: Array<Loop>): Loop {
        let existing = all.find(lf => lf.name == name); // Gibt es diese Funktion schon
        if (!name.match(/^[a-zA-Z][a-zA-Z0-9\.]*$/) || name.match(/^(LOOP|WHILE|END|DO|x(0|[1-9]\d*))$/)) throw new CoMoSimError("INVALIDFUNCTIONNAME", { fname: name });
        let f = new Loop(existing ? existing.type : LoopType.NEW, name, existing ? existing.index : undefined, nParam, comment);
        let rest = "";
        [f.statements,rest] = this.parseStatements(definition, all);
        if (rest) throw new CoMoSimError("UNEXPECTEDEND");
        return f;
    }
    public deserializeLoop(o, all: Array<Loop>): Loop {
        if (o == undefined) throw new CoMoSimError("INVALIDSTORAGEFORMAT", { element: "object" });
        if (o.name == undefined || typeof (o.name) != "string") throw new CoMoSimError("INVALIDSTORAGEFORMAT", { element: "name" });
        if (o.comment != undefined && typeof (o.comment) != "string") throw new CoMoSimError("INVALIDSTORAGEFORMAT", { element: "comment" });
        if (o.definition == undefined || typeof (o.definition) != "string") throw new CoMoSimError("INVALIDSTORAGEFORMAT", { element: "definition" });
        let existing = all.find(lf => lf.name == o.name); // Gibt es diese Funktion schon
        if (existing) throw new CoMoSimError("FUNCEXISTSALREADY", { fname: o.name });
        let f = new Loop(LoopType.USERLOOP, o.name, undefined, -1, o.comment);
        let rest = "";
        [f.statements, rest] = this.parseStatements(o.definition, all);
        if (rest) throw new CoMoSimError("UNEXPECTEDEND");
        if (o.n !== undefined) f.nParam = o.n;
        else f.nParam = f.getNumArgs();
        f.type = f.IsWhile() ? LoopType.USERWHILE : LoopType.USERLOOP;
        return f;
    }
    
}



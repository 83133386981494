import { Injectable } from "@angular/core";
import { PRFType, PRF, Func, VARARGS, FuncMu } from "../prf/prf";
import { Loop, LoopType, LoopStatement, StmLoop, StmCall, StmAssign, StmWhile } from "../loop/loop";
let blacklist = [
    "break", "case", "catch", "continue", "debugger", "default", "delete", "do", "else", "finally", "for", "function", "if", "in", "instanceof", "new", "return", "switch", "this", "throw", "try", "typeof", "var", "void", "while", "with",
    "class", "const", "enum", "export", "extends", "import", "super", "implements", "interface", "let", "package", "private", "protected", "public", "static", "yield",
    "null", "false", "true", "undefined", "NaN", "Infinity", "eval", "arguments",
    "int", "byte", "char", "goto", "long", "final", "float", "short", "double", "native", "throws", "boolean", "abstract", "volatile", "transient", "synchronized"
];
export { blacklist };
    
@Injectable()
export class CodeService {
    constructor() { }
    // ****************************************************************************
    // Allgemeine JS-Funktionen
    private getEncodeFunction(gap: string = ""):string { // Cantor-Kodierung
        let code = gap + "function enc(a, ...restArgs) {\n";
        code += gap + "\tlet c = 0n;\n";
        code += gap + "\tfor (let n of [a].concat(restArgs).reverse()){\n";
        code += gap + "\t\tn = typeof n === 'function' ? n() : n;\n";
        code += gap + "\t\tc = (c + n) * (c + n + 1n) / 2n + n;\n";
        code += gap + "\t}\n"
        code += gap + "\treturn c;\n"
        return code + gap + "}";
    }
    private getDecodeFunction(gap: string = ""): string { // Cantor-Dekodierung
        let code = gap + "function dec(i, c) {\n";
        code += gap + "\tfunction sqrtB(value) {\n";
        code += gap + "\t\tif (value < 0n) throw 'square root of negative numbers is not supported'\n";
        code += gap + "\t\tif (value < 2n) return value;\n";
        code += gap + "\t\tlet x0 = 1n;\n";
        code += gap + "\t\tlet x1 = ((value / x0) + x0) >> 1n;\n";
        code += gap + "\t\twhile (x0 !== x1 && x0 !== (x1 - 1n)) {\n";
        code += gap + "\t\t\tx0 = x1;\n";
        code += gap + "\t\t\tx1 = ((value / x0) + x0) >> 1n;\n";
        code += gap + "\t\t}\n";
        code += gap + "\t\treturn x0;\n";
        code += gap + "\t}\n";
        code += gap + "\tlet r = 0n;\n";
        code += gap + "\tc = typeof c === 'function' ? c() : c;\n";
        code += gap + "\tfor (; i >= 0n; i--) {\n";
        code += gap + "\t\tlet w = (sqrtB(8n * c + 1n) - 1n) / 2n;\n";
        code += gap + "\t\tr = c - w * (w + 1n) / 2n;\n";
        code += gap + "\t\tc = w - r;\n";
        code += gap + "\t}\n";
        code += gap + "\treturn r;\n"
        return code + gap + "}";
    }
    // ****************************************************************************
    // Primitiv-rekursive Funktionen
    public getPRFCode(f: PRF, lazy:boolean = false, gap: string = ""): string{
        if (f.type == PRFType.SUCC) // Nachfolgerfunktion
            return gap + "function S(a) { return (typeof a === 'function' ? a() : a) + 1n; }\n";
        else if (f.type == PRFType.CONS) // Konstante Funktion
            return gap + "function C(i) { return i; }\n";
        else if (f.type == PRFType.PROJ) // Projektionsfunktion
            return gap + "function U(i) { return typeof arguments[i] === 'function' ? arguments[i]() : arguments[i]; }\n";
        else if (f.type == PRFType.ENC) // Cantor-Enkodierung
            return this.getEncodeFunction(gap)+"\n";
        else if (f.type == PRFType.DEC) // Cantor-Dekodierung
            return this.getDecodeFunction(gap) + "\n";
        else if (f.definition.length == 1) { // Substitution oder Mu
            let code = gap + "function " + this.getFuncCode(f.definition[0].lf) + "{\n"; // Name der Funktion + Parameter
            if (f.helper) { // Hilfsfunktionen als lokale Funktinen
                for (let h of f.helper)
                    code += this.getPRFCode(h, true, gap + "\t");
            }
            if (f.definition[0].rf instanceof (FuncMu)) { // Mu
                let wVar = "n_" + f.name;
                code += gap + "\tlet " + wVar + " = 0n\n"
                code += gap + "\twhile(" + this.getFuncCode(f.definition[0].rf).replace("#", wVar) + " !== 0n)\n";
                code += gap + "\t\t" + wVar + " += 1n;\n"; 
                code += gap + "\t" +"return " + wVar + ";\n"; // Aufruf der Definition
                code += gap + "}\n";
            }
            else { // Substitution
                code += gap + "\t" +"return " + this.getFuncCode(f.definition[0].rf) + ";\n"; // Aufruf der Definition
                code += gap + "}\n";
            }
            return code;
        }
        else { // Rekursion
            let recfunc: Func = f.definition[1].rf.params[1] as Func;
            let code = gap + "function " + this.getFuncCode(recfunc) + "{\n"; // Name der Funktion + Parameter
            if (f.helper) { // Hilfsfunktionen als lokale Funktinen
                for (let h of f.helper)
                    code += this.getPRFCode(h,true, gap + "\t");
            }
            let recvar = recfunc.params[0];
            code += gap + "\tlet result = " + this.getFuncCode(f.definition[0].rf) + ";\n"; // Fall 0
            code += gap + "\tlet rec = typeof " + recvar + " === 'function' ? "+recvar+"() : "+recvar+";\n"; // Rekursionsvariable für Iteration
            code += gap + "\tfor (let " + recvar + " = 0n; " + recvar + " < rec; " + recvar + "++) {\n"; // Iteration
            code += gap + "\t\tresult = " + this.getFuncCode(f.definition[1].rf).replace("()=>"+this.getFuncCode(recfunc), "result").replace("("+f.param[0]+",","(C("+f.param[0]+"),") + ";\n";
            code += gap + "\t}\n";
            code += gap + "\t" +"return "+ "result;\n"
            code += gap + "}\n";
            return code;
        }
    }
    private getFuncCode(func:Func): string {
        let par = func.i == undefined ? [] : [func.i + "n"];
        for (let p of func.params) { // Aufbereitung der Parameter
            if (p instanceof Func)
                par.push("()=>"+this.getFuncCode(p)) // Eingebetteter Funktionsaufruf
            else if (p != VARARGS) {
                let vname = p;
                if (blacklist.indexOf(vname) >= 0) // Schlüsselwort als Parametername
                    vname = "_" + vname;
                par.push(vname); // konkrete Variable
            }
        }
        let name = func.f.name;
        if (blacklist.indexOf(name) >= 0)
            name = "_" + name;
        return name.replace(/\./g, "_") + "(" + par.join(",") + ")";
    }
    // ****************************************************************************
    // LOOP-Funktionen
    public getLoopCode(f:Loop, level: number = 0): string {
        if (f.type == LoopType.SUCC) // Nachfolgerfunktion
            return "function succ(i) { return i+1n; }\n";
        else if (f.type == LoopType.ENC) // Cantor-Enkodierung
            return this.getEncodeFunction() + "\n";
        else if (f.type == LoopType.DEC) // Cantor-Dekodierung
            return this.getDecodeFunction() + "\n";
        else { // Benutzerdefiniertes Loop-Programm
            let allVars = f.getUsedVars(); // Die verwendeten Variablen
            let name = f.name;
            if (blacklist.indexOf(name) >= 0)
                name = "_" + name;
            let code = "function " + name.replace(/\./g, "_") + "(";
            let sep = "";
            for (let i = 1; i <= f.nParam; i++) { // Argumente
                code += sep + "x" + i; // Bereitstellung als x_i
                sep = ", ";
                allVars.delete("x" + i); // keine weitere Initialisierung
            }
            code += ") {\n";
            code += "\tlet";
            sep = " ";
            for (let iv of allVars) { // Initialisierung der übrigen Variablen mit 0
                code += sep + iv + " = 0n"
                sep = ",\n\t\t";
            }
            code += ";\n";
            for (let s of f.statements) { // Aufruf der Anweisungen
                code += this.getStatementCode(s,level) + "\n";
            }
            code += "\treturn x0;\n"; // Rückgabe des Ergebnisses
            return code += "}\n";
        }
    }
    private getStatementCode(s: LoopStatement, level: number):string {
        if (s instanceof StmWhile) {
            let gap = "\t";
            for (let i = 0; i < level; i++)
                gap += "\t";
            let code = gap + "while (" + s.loopVar + ") {\n";
            for (let sub of s.statements) {
                code += this.getStatementCode(sub, level + 1) + "\n";
            }
            return code + gap + "}"
        }
        else if (s instanceof StmLoop) {
            let ivar = "i" + level;
            let gap = "\t";
            for (let i = 0; i < level; i++)
                gap += "\t";
            let code = gap + "for (let " + ivar + " = " + s.loopVar + "; " + ivar + " > 0n; " + ivar + "--) {\n";
            for (let sub of s.statements) {
                code += this.getStatementCode(sub,level + 1) + "\n";
            }
            return code + gap + "}"
        }
        else if (s instanceof StmCall) {
            let gap = "\t";
            for (let i = 0; i < level; i++)
                gap += "\t";
            let name = s.func.name;
            if (blacklist.indexOf(name) >= 0)
                name = "_" + name;
            let code = gap + s.targetVar + " = " + name.replace(/\./g, "_") + "(";
            let p = s.i == undefined ? [] : [s.i + "n"];
            p = p.concat(s.param.map(p => typeof (p) == "number" ? p + "n" : p));
            code += p.join(",") + ");";
            return code;
        }
        else if (s instanceof StmAssign) {
            let gap = "\t";
            for (let i = 0; i < level; i++)
                gap += "\t";
            return gap + s.targetVar + " = " + (typeof (s.sourceVar) == "number" ? s.sourceVar + "n" : s.sourceVar) + ";";
        }
        return "";
    }
}
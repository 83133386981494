import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ViewChild
} from '@angular/core';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { MatDialog } from '@angular/material/dialog';
import { EditLoop, LoopEditingService } from '../loopediting.service';
import { Loop } from '../loop';
import { LoopFormattingService } from '../loopformatting.service';
import { TransformService } from '../../general/transform.service';
import { DlgJSComponent } from '../../general/dlgjs/dlgsj.component';
import { TranslateService } from '@ngx-translate/core';
import { PRFEditingService } from '../../prf/prfediting.service';
import { Router } from '@angular/router';
import { CodeService } from '../../general/code.service';

@Component({
  selector: 'loop-editor',
  templateUrl: './loopeditor.component.html',
  styleUrls: ['./loopeditor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoopEditorComponent implements OnInit {
  public config = {
    //mode: 'cmprfmode',
    matchBrackets: true,
    autoCloseBrackets: true,
    singleCursorHeightPerLine: false,
    smartIndent: true,
    indentWithTabs:true
  }
  @ViewChild('cm', { static: true }) cm:CodemirrorComponent;
  @Input() func: EditLoop;
  sampleloop: Loop;
  showEval: boolean;
  nums = [0,1,2,3,4,5,6,7,8,9]
  constructor(private es: LoopEditingService,
    private fmt: LoopFormattingService,
    private prfEditorService: PRFEditingService,
    private transform: TransformService,
    private translate: TranslateService,
    private coding: CodeService,
    public dialog: MatDialog,
    private router: Router) {
  }
  
  public ngOnInit(): void {
    this.update(); // Update after opening
  }
  public update(): void{
    setTimeout(() => {
      this.cm.codeMirror.setOption("mode", this.func.editID);
    },50);
  }
  public onChange() {
    if (this.func.isBasic()) return;
    this.es.update(this.func);
    this.update();
    this.startsampling();
  }
  save() {
    this.es.saveChanges(this.func);
    this.update();
  }
  startsampling() {
    this.sampleloop = this.func.currentLoop;
  }
  openEval() {
    this.showEval = true;
    this.startsampling();
  }
  closeeval() {
    this.showEval = false;
  }
  expandLoop() {
    let expanded = this.transform.expandLoopFunction(this.func.currentLoop);
    this.func.currentCode = expanded.toIndented();
    this.onChange();
  }
  convertToPRF() {
    let prf = this.transform.loop2prf(this.func.currentLoop)
    this.prfEditorService.create("test",prf);
    this.router.navigateByUrl("/prf");
  }
  showJS() {
    let code = ""
    let required = this.transform.optimizeLoop(this.func.currentLoop);
    for (let func of this.func.currentLoop.addDependencies(new Set<Loop>([this.func.currentLoop]), true)) {
      code += this.coding.getLoopCode(func);
    }
    this.dialog.open(DlgJSComponent, {
      data: {
        code: code,
        title: this.func.currentName,
        okText: this.translate.instant("CLOSE")
      }
    });
  }
  morenums() {
    for (let i = 0; i < 10; i++){
      this.nums.push(this.nums.length)
    }
  }
}


import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import 'codemirror/mode/javascript/javascript';

export interface DlgJSData {
  code: string;
  title: string;
  okText: string;
}

@Component({
  templateUrl: './dlgjs.component.html',
  styleUrls: ['./dlgjs.component.css']
})
export class DlgJSComponent {
  public config = {
    mode: { name: "javascript", json: true },
    matchBrackets: true,
    indentWithTabs: true,
    readOnly: true
  }
  public code: string;
  public title: string;
  public okText: string;
  constructor(public dialogRef: MatDialogRef<DlgJSComponent>, @Inject(MAT_DIALOG_DATA) public data: DlgJSData) {
    this.code = data.code;
    this.title = "Javascript: "+data.title;
    this.okText = data.okText;
  }
}

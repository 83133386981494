var VARARGS = "*"; // Das Zeichen für weitere Parameter
export { VARARGS };
    
export const enum PRFType{
    SUCC = 0,   // Nachfolgerfunktion
    CONS = 1,   // Konstante Funktion
    PROJ = 2,   // Projektionsfunktion
    ENC = 3,    // Kodierungsfunktion
    DEC = 4,    // Dekodierungsfunktion
    USERPRIM = 10,  // Nutzerdefinierte primitive Funktion
    USERMU = 12,  // Nutzerdefinierte Mü-Funktion
    HELP = 100, // Hilfsfunktion
    NEW = -1    // Neue ungespeicherte Funktion
}

// Eine primitv rekursive Funktion oder eine ganze Klasse
export class PRF {
    type: PRFType;              // Art der Funktion
    name: string;               // Name der Funktion: [A-za-z]+\d*
    index: string|number;       // Spezifizierung der Funktion: undefined oder Variable oder Zahl
    param: Array<string>;       // Parameter-Variablen der Funktion: [a, b, *] oder []
    definition: Array<Equation>;// Definition der Funktion durch Gleichungen: undefined bei Basisfunktionen sonst mindestens 1 Element
    comment: string;            // Textuelle Beschreibung der Funktion: "" ist erlaubt
    helper: Array<PRF>;         // Hilfsfunktionen
    constructor(id: PRFType, name: string, index: string|number, param: Array<string>, comment:string) {
        this.type = id; this.name = name; this.index = index; this.param = param; this.comment = comment;
    }
    public GetFType(): string{
        switch (this.type) {
            case PRFType.SUCC:
            case PRFType.CONS:
            case PRFType.PROJ: return "B";
            case PRFType.ENC:
            case PRFType.DEC: return "H";
            case PRFType.USERPRIM: return "P";
            case PRFType.USERMU: return "μ";
            default: return "?";
        }
    }
    public toString():string { // Die vollständige Funktionsdefinition
        if (this.definition == undefined) // Bei Basisfunktionen
            return this.toCallString(); // ohne Definition
        return this.definition.map(d => d.toString()).join("\n"); // sonst vollständige Definition
    }

    public toCallString():string { // Nur der Funktionsaufruf
        return this.name + (this.index == undefined ? "" : "_" + this.index) + "(" + this.param.join(",") + ")";
    }
    
    public isBasic():boolean { // Eine der 3 Basisfunktionen oder eine Systemfunktion?
        return (this.type == PRFType.SUCC ||
            this.type == PRFType.CONS ||
            this.type == PRFType.PROJ ||
            this.type == PRFType.ENC ||
            this.type == PRFType.DEC)
    }

    public addDependencies(r: Set<PRF> = new Set<PRF>(), deep:boolean=true): Set<PRF> { // Abhängigkeiten dieser Funktion bestimmen
        if (this.definition) { // Abhängigkeiten der Definition
            for (let d of this.definition)
                d.addDependencies(r, deep);
        }
        return r;
    }

    public getMinMaxParam(): [number,number] { // Zulässige Anzahl der Parameter
        let min = 0;
        let max = 0;
        for (let p of this.param) {
            if (p == VARARGS) // Beliebige weitere Parameter möglich --> Funktionsklasse
                max = Infinity
            else // Ein weiterer fester Parameter
                min++
        }
        if (max == 0) // Nicht beliebig viele
            max = min; // Also feste Anzahl
        return [min, max]
    }
    
    public serialize():object { // Serialisierbares Objekt erzeugen
        let o = {
            definition: undefined,
            comment: this.comment
        };
        if (this.definition) // Nur bei benutzerdefinierten gespeicherten Funktionen
            o.definition = this.definition.map(d => d.toString()); // Definition serialisieren
        if (this.helper && this.helper.length) // Wenn es Hilfsfunktionen gibt
            o["helper"] = this.helper.map(h => h.serialize()); // Diese serialisieren
        return o;
    }
    public isMu(): boolean {
        if (!this.definition) return false;
        if (this.definition.find(d => d.rf instanceof (FuncMu)))
            return true;
        for (let e of this.definition) {
            if (e.rf.f.isMu())
                return true;
            for (let p of e.rf.params)
                if (p instanceof (FuncMu) || (p instanceof(Func) && p.f != this && p.f.isMu()))
                    return true;
        }
        if (!this.helper) return false;
        for (let h of this.helper) {
            if (h.isMu())
                return true;
        }
        return false;
    }
}
// Ein Funktionsaufruf
export class Func{
    f: PRF; // Die aufgerufene Funktion
    i: string|number; // Konkreter Index der Funktion
    params: Array<string | Func> // Die Parameter des Aufrufs (Variablen oder andere Aufrufe)

    constructor(f: PRF, i:string|number, params: Array<string | Func>) {
        this.f = f;
        this.i = i;
        this.params = params;
    }
    
    public toString() { // Textuelle Ausgabe des Funktionsaufrufs
        return this.f.name + (this.i == undefined ? "" : "_" + this.i) + "(" + this.params.join(",") + ")";
    }

    public addDependencies(r: Set<PRF>, deep: boolean = true) { // Abhängigkeiten des Aufrufs hinzufügen
        if (!r.has(this.f)) { // Funktion des Aufrufs ist noch nicht in der Liste
            r.add(this.f); // Hinzufügen
            if (deep || this.f.type == PRFType.HELP)
                this.f.addDependencies(r,deep); // Deren Abhängigkeiten hinzufügen
        }
        for (let p of this.params) { // Die Parameter des Funktionsaufrufs prüfen
            if (p instanceof (Func)) // Behinhaltet weitere Funktionsaufrufe
                p.addDependencies(r, deep); // Deren Abhängigkeiten hinzufügen
        }
    }
}

export class FuncMu extends Func {
    mui: number;
    constructor(f: PRF, i: string | number, params: Array<string | Func>) {
        super(f, i, params);
        this.mui = params.indexOf("#");
    }

    public toString() { // Textuelle Ausgabe des Funktionsaufrufs
        return "["+this.f.name + (this.i == undefined ? "" : "_" + this.i) + "(" + this.params.join(",") + ")]";
    }
}

export class Equation{
    lf: Func; // Funktionsaufruf der linken Seite
    rf: Func; // Funktionsaufruf der rechten Seite

    constructor(lf:Func, rf:Func) {
        this.lf = lf;
        this.rf = rf;
    }
        
    public toString() { // Textuelle Ausgabe der Gleichung
        return this.lf.toString() + "=" + this.rf.toString();
    }
    
    public addDependencies(r: Set<PRF>, deep: boolean = true) { // Abhängigkeiten der Gleichung bestimmen
        this.lf.addDependencies(r, deep); // Abhängigkeiten auf linker Seite
        this.rf.addDependencies(r, deep); // und auf der rechten Seite
    }
}


import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { PRFBaseComponent } from './prf/prfbase/prfbase.component';
import { PRFGeneratorComponent } from './prf/generator/generator.component';
import { DatabaseService } from './general/database.service';
import { PRFGeneratorService } from './prf/prfgenerator.service';
import { SafeHtmlPipe } from "./safehtml.pipe"
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import "codemirror/addon/edit/matchbrackets"
import "codemirror/addon/edit/closebrackets"
import { ConfirmComponent } from './general/confirm/confirm.component';
import { PRFFormattingService } from './prf/prfformatting.service';
import { HomeComponent } from './home/home.component';
import { PRFViewComponent } from './prf/prfview/prfview.component';
import { PRFEditorComponent } from './prf/prfeditor/prfeditor.component';
import { PRFEditingService } from './prf/prfediting.service';
import { ErrorService } from './general/error.service';
import { PRFParsingService } from './prf/prfparsing.service';
import { SampleService } from './general/sample.service';
import { PRFEvalComponent } from './prf/prfeval/prfeval.component';
import { LoopViewComponent } from './loop/loopview/loopview.component';
import { LoopEditingService } from './loop/loopediting.service';
import { LoopParsingService } from './loop/loopparsing.service';
import { LoopBaseComponent } from './loop/loopbase/loopbase.component';
import { LoopEditorComponent } from './loop/loopeditor/loopeditor.component';
import { LoopFormattingService } from './loop/loopformatting.service';
import { LoopEvalComponent } from './loop/loopeval/loopeval.component';
import { TransformService } from './general/transform.service';
import { DlgJSComponent } from './general/dlgjs/dlgsj.component';
import { CodeService } from './general/code.service';
import { KatexModule } from 'ng-katex';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/");
}

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'prf', component: PRFViewComponent },
  { path: 'loop', component: LoopViewComponent },
  { path: 'login', component: PRFViewComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: HomeComponent }
];

export const tooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 100,
  hideDelay: 1000,
  touchendHideDelay: 1000
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PRFViewComponent,
    PRFBaseComponent,
    PRFGeneratorComponent,
    PRFEditorComponent,
    SafeHtmlPipe,
    ConfirmComponent,
    DlgJSComponent,
    PRFEvalComponent,
    LoopViewComponent,
    LoopBaseComponent,
    LoopEditorComponent,
    LoopEvalComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTabsModule,
    MatSliderModule,
    MatSnackBarModule,
    MatCardModule,
    MatGridListModule,
    MatExpansionModule,
    MatSelectModule,
    ScrollingModule,
    CodemirrorModule,
    KatexModule
  ],
  exports: [TranslateModule],
  providers: [ErrorService,
    DatabaseService,
    PRFGeneratorService,
    PRFFormattingService,
    PRFEditingService,
    PRFParsingService,
    SampleService,
    LoopEditingService,
    LoopParsingService,
    LoopFormattingService,
    TransformService,
    CodeService,
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults }
  ],
  bootstrap: [AppComponent],
  entryComponents: [HomeComponent, ConfirmComponent,DlgJSComponent, PRFViewComponent, PRFGeneratorComponent, LoopViewComponent]
})
export class AppModule { }

import { Component, ViewChild, ElementRef, DoCheck, IterableDiffers } from '@angular/core';
import { DatabaseService } from "../../general/database.service"
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../../general/confirm/confirm.component';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from '../../general/error.service';
import { LoopEditingService } from '../loopediting.service';
import { Loop } from '../loop';
import { LoopFormattingService } from '../loopformatting.service';

@Component({
  selector: 'loop-base',
  templateUrl: './loopbase.component.html',
  styleUrls: ['./loopbase.component.css']
})
// Komponente zur Anzeige der aktuellen Funktionsbasis
export class LoopBaseComponent implements DoCheck {
  filteredFunctions: Array<Loop>; // Die angezeigten Funktionen
  searchtext: string; // Der aktuelle Suchtext
  showInfo: Boolean = false; // Wird gerade die Definition angezeigt
  iterableDiffer;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef; // Verstecktes Feld für Datei-Input
  sDefLoop1: string;
  sDefLoop2: string;

  constructor(private err: ErrorService,
    private translate: TranslateService,
    private db: DatabaseService,
    private es: LoopEditingService,
    public dialog: MatDialog,
    public fmt:LoopFormattingService,
    private _iterableDiffers: IterableDiffers) { 
    this.filteredFunctions = [].concat(this.db.loops);
    this.searchtext = "";
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
  }
  ngDoCheck() {
    if (this.iterableDiffer.diff(this.db.loops))
      this.filter();
  this.sDefLoop1 = this.translate.instant("DEFLOOP1");
  this.sDefLoop2 = this.translate.instant("DEFLOOP2");
  }
  filter() { // Bei Änderung des Suchfilters
    if (this.searchtext == "")
      this.filteredFunctions = [].concat(this.db.loops);
    else {
      let re = new RegExp(this.searchtext, "i")
      this.filteredFunctions = this.db.loops.filter(f => !this.searchtext || f.name.search(re) != -1 || f.comment.search(re) != -1)
    }
  }
  clear() { // Zurücksetzen des Suchfilters
    this.searchtext = "";
    this.filter();
  }

  download() { // Button: Download der aktuellen Funktionen als JSON-File
    var jsonBase = this.db.getJSONLoop(4);
    var url = "data:application/json;base64," + btoa(jsonBase);
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', "loops.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  import() { // Button: Import von Funktionen
    this.fileInput.nativeElement.value = "";
    this.fileInput.nativeElement.click();
  }
  onImport() { // Es wurde eine Datei für den Import ausgewählt
    let db = this.db;
    let es = this.es;
    let file = this.fileInput.nativeElement.files[0];
    let errsvc = this.err;
    let self = this;
    this.dialog.open(ConfirmComponent, {
      data: {
        title: this.translate.instant("IMPORT"),
        message: this.translate.instant("REALLYIMPORT"),
        cancelText: this.translate.instant("NOKEEP"),
        okText: this.translate.instant("YESIMPORT")
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        let reader = new FileReader();
        reader.onload = function (progressEvent) {
          try {
            es.stopEditSet(new Set(db.loops));
            db.loadFromJSONLoop(this.result);
            self.showInfo = false;
          }
          catch (err) {
            errsvc.show(err.msg,err.msgParam);
            return;
          }
        };
        reader.onerror = function (evt) {
          if (reader.error)
            errsvc.show(reader.error.toString());
          else
            errsvc.show("Unknown file error");
        }
        reader.readAsText(file,"windows-1252");
      }
    });
  }
  
  onEditFunction(f: Loop) { // Funktion geklickt: wird nun bearbeitet
    if (!f.isBasic())
      this.es.edit(f);
  }
  onDeleteFunction(f: Loop) { // Funktion zu löschen
    let deps = this.db.usedByLoop(f); // Abhängigkeiten ermitteln
    let addtext = "";
    if (deps.size > 0) { // Es gibt Funktionen die diese benötigen
      addtext = this.translate.instant("FUNCTIONISUSED");
      addtext += "<ul>"
      for (let d of deps)
        addtext += "<li class='mono'>" + this.fmt.colorizeFormula(d.toCallString())+"</li>"
      addtext += "</ul>"
      addtext += this.translate.instant("AUTODELETEDTOO");
    }
    this.dialog.open(ConfirmComponent, { // Löschen bestätigen
      data: {
        title: this.translate.instant("DELFUNCTION"),
        message: this.translate.instant("REALLYDELETE", { fname: f.name }) + addtext,
        cancelText: this.translate.instant("DONTDELETE"),
        okText: this.translate.instant("YESDELETE")
    } }).afterClosed().subscribe(result => {
      if (result) {
        deps.add(f);
        this.es.stopEditSet(deps); // Laufende Bearbeitungen abbrechen
        this.db.deleteLoop(deps); // Funktionen löschen
        this.filter(); // Filterung aktualisieren
      }
    });
  }
  toggleInfo() {
    this.showInfo = !this.showInfo;
  }
}

import { Component, ViewChild, ElementRef, DoCheck, IterableDiffers } from '@angular/core';
import { DatabaseService } from "../../general/database.service"
import { PRF } from "../prf"
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../../general/confirm/confirm.component';
import { PRFFormattingService } from '../prfformatting.service';
import { TranslateService } from '@ngx-translate/core';
import { PRFEditingService } from '../prfediting.service';
import { ErrorService } from '../../general/error.service';

@Component({
  selector: 'prf-base',
  templateUrl: './prfbase.component.html',
  styleUrls: ['./prfbase.component.css']
})
// Komponente zur Anzeige der aktuellen Funktionsbasis
export class PRFBaseComponent implements DoCheck {
  filteredFunctions: Array<PRF>; // Die angezeigten Funktionen
  searchtext: string; // Der aktuelle Suchtext
  showInfo: Boolean = false; // Wird gerade die Definition angezeigt
  iterableDiffer;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef; // Verstecktes Feld für Datei-Input
  sDefSubst: string;
  sDefPrRec: string;
  sDefMuRec: string;
  constructor(private err: ErrorService,
    private translate: TranslateService,
    private db: DatabaseService,
    private fmt: PRFFormattingService,
    private es: PRFEditingService,
    public dialog: MatDialog,
    private _iterableDiffers: IterableDiffers) { 
    this.filteredFunctions = [].concat(this.db.functions);
    this.searchtext = "";
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
  }
  ngDoCheck() {
    if (this.iterableDiffer.diff(this.db.functions))
      this.filter();
    this.sDefSubst = this.translate.instant("DEFSUBST");
    this.sDefPrRec = this.translate.instant("DEFPREC");
    this.sDefMuRec = this.translate.instant("DEFMU");
  }
  filter() { // Bei Änderung des Suchfilters
    if (this.searchtext == "")
      this.filteredFunctions = [].concat(this.db.functions);
    else {
      let re = new RegExp(this.searchtext, "i")
      this.filteredFunctions = this.db.functions.filter(f => !this.searchtext || f.name.search(re) != -1 || f.comment.search(re) != -1)
    }
  }
  clear() { // Zurücksetzen des Suchfilters
    this.searchtext = "";
    this.filter();
  }

  download() { // Button: Download der aktuellen Funktionen als JSON-File
    var jsonBase = this.db.getJSONPRF(4);
    var url = "data:application/json;base64," + btoa(jsonBase);
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', "prfbase.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  import() { // Button: Import von Funktionen
    this.fileInput.nativeElement.value = "";
    this.fileInput.nativeElement.click();
  }
  onImport() { // Es wurde eine Datei für den Import ausgewählt
    let db = this.db;
    let es = this.es;
    let file = this.fileInput.nativeElement.files[0];
    let errsvc = this.err;
    let self = this;
    this.dialog.open(ConfirmComponent, {
      data: {
        title: this.translate.instant("IMPORT"),
        message: this.translate.instant("REALLYIMPORT"),
        cancelText: this.translate.instant("NOKEEP"),
        okText: this.translate.instant("YESIMPORT")
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        let reader = new FileReader();
        reader.onload = function (progressEvent) {
          try {
            es.stopEditSet(new Set(db.functions));
            db.loadFromJSONPRF(this.result);
            self.showInfo = false;
          }
          catch (err) {
            errsvc.show(err.msg,err.msgParam);
            return;
          }
        };
        reader.onerror = function (evt) {
          if (reader.error)
            errsvc.show(reader.error.toString());
          else
            errsvc.show("Unknown file error");
        }
        reader.readAsText(file,"windows-1252");
      }
    });
  }
  
  onEditFunction(f: PRF) { // Funktion geklickt: wird nun bearbeitet
    if (!f.isBasic())
      this.es.edit(f);
  }
  onDeleteFunction(f: PRF) { // Funktion zu löschen
    let deps = this.db.usedByPRF(f); // Abhängigkeiten ermitteln
    let addtext = "";
    if (deps.size > 0) { // Es gibt Funktionen die diese benötigen
      addtext = this.translate.instant("FUNCTIONISUSED");
      addtext += "<ul>"
      for (let d of deps)
        addtext += "<li class='mono'>" + this.fmt.colorizeFormula(d.toString()).replace(/\n/g,"<br>")+"</li>"
      addtext += "</ul>"
      addtext += this.translate.instant("AUTODELETEDTOO");
    }
    this.dialog.open(ConfirmComponent, { // Löschen bestätigen
      data: {
        title: this.translate.instant("DELFUNCTION"),
        message: this.translate.instant("REALLYDELETE", { fname: f.name }) + addtext,
        cancelText: this.translate.instant("DONTDELETE"),
        okText: this.translate.instant("YESDELETE")
    } }).afterClosed().subscribe(result => {
      if (result) {
        deps.add(f);
        this.es.stopEditSet(deps); // Laufende Bearbeitungen abbrechen
        this.db.deletePRF(deps); // Funktionen löschen
        this.filter(); // Filterung aktualisieren
      }
    });
  }
  toggleInfo() {
    this.showInfo = !this.showInfo;
  }
}

<div class="func-container">
  <div style="height:50px">
    <mat-toolbar color="secondary" style="height:100%">
      <button mat-button mat-icon-button *ngIf="!showInfo" (click)="toggleInfo()" matTooltip="{{ 'HELP' | translate}}" style="margin-left:-10px;margin-right:5px">
        <mat-icon>help_outline</mat-icon>
      </button>
      <button mat-button mat-icon-button *ngIf="showInfo" (click)="toggleInfo()" matTooltip="{{ 'LOOP' | translate}}" style="margin-left:-10px;margin-right:5px">
        <mat-icon>toc</mat-icon>
      </button>
      <mat-form-field floatLabel="never" style="flex:auto;">
        <input matInput type="text" placeholder="{{'SEARCHFUNC' | translate}}" [(ngModel)]="searchtext" (input)="filter()" spellcheck="false">
      </mat-form-field>
      <button mat-button mat-icon-button matSuffix (click)="clear()" matTooltip="{{ 'EMPTYSEARCH' | translate}}">
        <mat-icon>clear</mat-icon>
      </button>
      <span class="flex"></span>
      <button mat-button mat-icon-button (click)="import()" matTooltip="{{ 'IMPORTDEF' | translate}}">
        <mat-icon>folder_open</mat-icon>
      </button>
      <input type="file" accept=".json" hidden #fileInput (change)="onImport()" />
      <button mat-button mat-icon-button (click)="download()" matTooltip="{{ 'EXPORTDEF' | translate}}">
        <mat-icon>save_alt</mat-icon>
      </button>
    </mat-toolbar>
  </div>
  <mat-nav-list *ngIf="!showInfo" style="flex:1;overflow-y:auto;overflow-x:hidden">
    <mat-list-item *ngFor="let f of filteredFunctions" (click)="onEditFunction(f)">
      <mat-icon mat-list-icon fontSet="roboto-mono" style="text-align:center;color:white;line-height: 1.1em;"
                [ngStyle]="{'background-color': f.GetFType() == 'W' ? 'indianred' : (f.GetFType() == 'L' ? 'lightskyblue' : 'lightgrey')}">
                {{f.GetFType()}}
      </mat-icon>
      <p matLine class="mono" style="font-size:20px;" [innerHTML]="fmt.colorizeFormula(f.toCallString()) | safeHtml">  </p>
      <p matLine>
        <span [translate]="f.comment"></span>
      </p>
      <button mat-button mat-icon-button class="showonhover" (click)="onDeleteFunction(f)"  *ngIf="!f.isBasic()" matTooltip="{{ 'DELETEFUNC' | translate}}">
        <mat-icon>delete_forever</mat-icon>
      </button>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-nav-list>
  <div *ngIf="showInfo" style="flex:1;overflow-y:auto;overflow-x:hidden">
    <mat-card>
      <mat-card-header>
        <mat-card-title style="margin: auto"><b translate>DEFINITION</b></mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin-top:15px">
        <h3><b><u translate>LOOPFUNC</u></b></h3>
        <ng-katex-paragraph [paragraph]="sDefLoop1"></ng-katex-paragraph>
        <ng-katex-paragraph [paragraph]="sDefLoop2"></ng-katex-paragraph>
        <span [innerHTML]="'DEFLOOP3' | translate"></span><br>
        <p>
          <span [innerHTML]="'DEFLOOP9' | translate"></span><br>
          <span class='mono'>x0 := <span class="cm-known">succ</span>(x1)</span><br>
          <span [innerHTML]="'DEFLOOP10' | translate"></span>
        </p>
        <p>
          <span [innerHTML]="'DEFLOOP11' | translate"></span><br>
          <span class='mono'><span class="cm-help">LOOP</span> x1 <span class="cm-help">DO</span> x0 := <span class="cm-known">succ</span>(x0) <span class="cm-help">END</span></span><br>
          <span [innerHTML]="'DEFLOOP12' | translate"></span>
        </p>
        <h3><b><u translate>WHILEFUNC</u></b></h3>
        <p>
          <span [innerHTML]="'DEFWHILE1' | translate"></span><br>
          <span class='mono'><span class="cm-help">WHILE</span> x1 <span class="cm-help">DO</span> x1 := <span class="cm-known">pred</span>(x1) <span class="cm-help">END</span></span><br>
          <span [innerHTML]="'DEFWHILE2' | translate"></span><br>
        </p>
      </mat-card-content>
    </mat-card>
    <mat-card style="margin-top:5px">
      <mat-card-header>
        <mat-card-title style="margin: auto"><b translate>HELP</b></mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin-top:15px">
        <p [innerHTML]="'LOOPINCOMOSIM1' | translate"></p>
        <p [innerHTML]="'LOOPINCOMOSIM2' | translate"></p>
        <mat-divider></mat-divider>
        <p style="padding-top:15px">
          <span [innerHTML]="'DEFLOOP13' | translate"></span>
        </p>
        <mat-divider></mat-divider>
        <p style="padding-top:15px">
          <b translate>EXAMPLE</b>:
        </p>
        <p class='mono'>x0 := <span class="cm-known">succ</span>(x0)</p>
        <p>
          <span [innerHTML]="'LOOPEXAMPLECOMMENT' | translate"></span>
          <ng-katex equation="one()=1"></ng-katex>
        </p>

        <mat-divider style="margin-left:5%; width:90%"></mat-divider>
        <p style="padding-top:15px" class='mono'><span class="cm-help">LOOP</span> x1 <span class="cm-help">DO</span> x0 := <span class="cm-known">succ</span>(x0) <span class="cm-help">END</span></p>
        <p>
        <span [innerHTML]="'LOOPEXAMPLECOMMENT1' | translate"></span>
        <ng-katex equation="ident(x)=x"></ng-katex>
        </p>

        <mat-divider style="margin-left:5%; width:90%"></mat-divider>
        <p style="padding-top:15px" class='mono'>x0 := <span class="cm-known">ident</span>(x1);<br><span class="cm-help">LOOP</span> x2 <span class="cm-help">DO</span> x0 := <span class="cm-known">succ</span>(x0) <span class="cm-help">END</span></p>
        <p>
          <span [innerHTML]="'LOOPEXAMPLECOMMENT2' | translate"></span>
          <ng-katex equation="add(a,b)=a+b"></ng-katex>
        </p>

        <mat-divider style="margin-left:5%; width:90%"></mat-divider>
        <p style="padding-top:15px" class='mono'>
          <span class="cm-help">LOOP</span> x1 <span class="cm-help">DO</span><br>
          x0 := <span class="cm-known">add</span>(x0,x2);<br>
          x2 := <span class="cm-known">one</span>();<br>
          <span class="cm-help">END</span>
        </p>
        <p>
          <span [innerHTML]="'LOOPEXAMPLECOMMENT3' | translate"></span>
        </p>

        <mat-divider style="margin-left:5%; width:90%"></mat-divider>
        <p style="padding-top:15px" class='mono'>
          x3 := <span class="cm-known">notequal</span>(x1,x2);<br>
          <span class="cm-help">WHILE</span> x3 <span class="cm-help">DO</span><br>
          x0 := <span class="cm-known">succ</span>(x0);<br>
          x2 := <span class="cm-known">mult</span>(x0,x0);<br>
          x3 := <span class="cm-known">notequal</span>(x1,x2);<br>
          <span class="cm-help">END</span>
        </p>
        <p>
          <span [innerHTML]="'WHILEEXAMPLECOMMENT' | translate"></span>
        </p>

        <mat-divider></mat-divider>
        <p style="padding-top:15px">
          <b translate>MOREINCOMOSIM</b>:
        </p>
        <p>
        <span [innerHTML]="'MORELOOPINCOMOSIMASSIGN' | translate"></span><br>
        <span class='mono'>x0 := x1</span>
        </p>
        <p>
        <span [innerHTML]="'MORELOOPINCOMOSIMCONST' | translate"></span><br>
        <span class='mono'>x0 := 5</span><br>
        <span class='mono'>x0 := <span class='cm-known'>succ</span>(5)</span>
        </p>
        <p [innerHTML]="'MORELOOPINCOMOSIMCANTOR' | translate"></p>
        <ng-katex equation="\begin{aligned}enc(a_1,a_2,\dots,a_n) &= \langle a_1, a_2, \dots, a_n\rangle \\dec_i(\langle a_1, a_2, \dots, a_n\rangle) &= a_{i+1}\end{aligned}"></ng-katex>
      </mat-card-content>
    </mat-card>
  </div>
</div>
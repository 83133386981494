<div class="func-container">
  <div style="height:50px">
    <mat-toolbar color="secondary" style="height:100%">
      <button mat-button mat-icon-button *ngIf="!showInfo" (click)="toggleInfo()" matTooltip="{{ 'HELP' | translate}}" style="margin-left:-10px;margin-right:5px">
        <mat-icon >help_outline</mat-icon>
      </button>
      <button mat-button mat-icon-button *ngIf="showInfo" (click)="toggleInfo()" matTooltip="{{ 'PRF' | translate}}" style="margin-left:-10px;margin-right:5px">
        <mat-icon >toc</mat-icon>
      </button>
      <mat-form-field floatLabel="never" style="flex:auto;">
        <input matInput type="text" placeholder="{{'SEARCHFUNC' | translate}}" [(ngModel)]="searchtext" (input)="filter()" spellcheck="false">
      </mat-form-field>
      <button mat-button mat-icon-button matSuffix (click)="clear()" matTooltip="{{ 'EMPTYSEARCH' | translate}}">
        <mat-icon>clear</mat-icon>
      </button>
      <span class="flex"></span>
      <button mat-button mat-icon-button (click)="import()" matTooltip="{{ 'IMPORTDEF' | translate}}">
        <mat-icon>folder_open</mat-icon>
      </button>
      <input type="file" accept=".json" hidden #fileInput (change)="onImport()" />
      <button mat-button mat-icon-button (click)="download()" matTooltip="{{ 'EXPORTDEF' | translate}}">
        <mat-icon>save_alt</mat-icon>
      </button>
    </mat-toolbar>
  </div>
  <mat-nav-list *ngIf="!showInfo" style="flex:1;overflow-y:auto;overflow-x:hidden">
    <mat-list-item *ngFor="let f of filteredFunctions" (click)="onEditFunction(f)">
      <mat-icon mat-list-icon fontSet="roboto-mono" style="text-align:center;color:white;line-height: 1.1em;" 
                [ngStyle]="{'background-color': f.GetFType() == 'μ' ? 'indianred' : (f.GetFType() == 'P' ? 'lightskyblue' : 'lightgrey')}">
                {{f.GetFType()}}
      </mat-icon>
      <p matLine class="mono" style="font-size:20px;" *ngFor="let line of fmt.colorizeFormula(f.toCallString()).split('\n')" [innerHTML]="line | safeHtml">  </p>
      <p matLine>
        <span [translate]="f.comment"></span>
      </p>
      <button mat-button mat-icon-button class="showonhover" (click)="onDeleteFunction(f)" *ngIf="!f.isBasic()" >
        <mat-icon>delete_forever</mat-icon>
      </button>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-nav-list>
  <div *ngIf="showInfo" style="flex:1;overflow-y:auto;overflow-x:hidden">
    <mat-card>
      <mat-card-header>
        <mat-card-title style="margin: auto"><b translate>DEFINITION</b></mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin-top:15px">
        <h3><b><u translate>PRFUNC</u></b></h3>
        <p translate>PRFDEF</p>
        <p style="padding-top:15px">
          <b translate>THREEBASE</b>
        </p>
        <ul>
          <li><span translate>SUCCESSORFUNCTION</span>: <ng-katex equation="S(a) = a + 1"></ng-katex></li>
          <li><span translate>CONSTANTFUNCTIONS</span>: <ng-katex equation="C_i(a_1,a_2,...,a_n)=i"></ng-katex></li>
          <li><span translate>PROJECTIONFUNCTIONS</span>: <ng-katex equation="U_i(a_1,a_2,...,a_n)=a_i"></ng-katex></li>
        </ul>
        <p style="padding-top:15px">
          <b translate>SUBSTITUTION</b>:
        </p>
        <ng-katex equation="\begin{aligned}\\h(a_1,a_2,...,a_n)=f(&g_1(a_1,a_2,...,a_n),\\&g_2(a_1,a_2,...,a_n),\\&...,\\&g_k(a_1,a_2,...,a_n))\end{aligned}"></ng-katex>
        <ng-katex-paragraph [paragraph]="sDefSubst"></ng-katex-paragraph>
        
        <p style="padding-top:15px">
          <b translate>PRECURSION</b>:
        </p>
        <ng-katex equation="\begin{aligned}\\h(C_0(),a_1,a_2,...,a_n)&=f(a_1,a_2,...,a_n)\\h(S(r),a_1,a_2,...,a_n)&=g(r,h(r, a_1,a_2,...,a_n),\\&\;\;\;\;\;\;\;\;a_1,a_2,...,a_n)\end{aligned}"></ng-katex>
        <ng-katex-paragraph [paragraph]="sDefPrRec"></ng-katex-paragraph>
        <h3><b><u translate>MUFUNC</u></b></h3>
        <p translate>MUDEF</p>
        <p style="padding-top:15px">
          <b translate>MUOPERATOR</b>:
        </p>
        <ng-katex-paragraph [paragraph]="sDefMuRec"></ng-katex-paragraph>
      </mat-card-content>
    </mat-card>
    <mat-card style="margin-top:5px">
      <mat-card-header>
        <mat-card-title style="margin: auto"><b translate>HELP</b></mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin-top:15px">
        <p [innerHTML]="'PRFINCOMOSIM1' | translate"></p>
        <p [innerHTML]="'PRFINCOMOSIM2' | translate"></p>
        <p [innerHTML]="'MUFINCOMOSIM1' | translate"></p>
        <ng-katex equation="\mu F^i(x_1, x_2, …, x_i) = \textbf{\textcolor{red}[} F^{i+1}(\text{\textbf{\textcolor{red}\#}}, x_1, x_2, ..., x_i)\textbf{\textcolor{red}]}"></ng-katex>
        <p [innerHTML]="'MUFINCOMOSIM2' | translate"></p>
        <mat-divider></mat-divider>
        
        <p style="padding-top:15px">
          <b translate>EXAMPLE</b>:
        </p>
        <p class="mono"><span class="cm-unknown">s2</span>(a,b)=<span class="cm-known">S</span>(<span class="cm-known">U_2</span>(a,b))</p>
        <p [innerHTML]="'PRFEXAMPLECOMMENT' | translate"></p>
        
        <mat-divider style="margin-left:5%; width:90%"></mat-divider>
        <p class="mono" style="padding-top:15px">
          <span><span class="cm-unknown">P</span>(<span class="cm-known">C_0</span>())=<span class="cm-known">C_0</span>()</span><br>
          <span><span class="cm-unknown">P</span>(<span class="cm-known">S</span>(r))=<span class="cm-known">U_1</span>(r,<span class="cm-unknown">P</span>(r))</span>
        </p>
        <p [innerHTML]="'PRFEXAMPLECOMMENT1' | translate"></p>
        
        <mat-divider style="margin-left:5%; width:90%"></mat-divider>
        <p class="mono" style="padding-top:15px">
          <!--sqrtest(a,b)=NotEqual(Mult1(a,b),U_2(a,b))-->
          <span class="cm-known">mult1</span>(a,b)=<span class="cm-known">mult</span>(<span class="cm-known">U_1</span>(a,b),<span class="cm-known">U_1</span>(a,b))<br>
          <span class="cm-known">sqrtest</span>(a,b)=<span class="cm-known">notEqual</span>(<span class="cm-known">mult1</span>(a,b),<span class="cm-known">U_2</span>(a,b))<br>
          <span><span class="cm-unknown">muSqr</span>(a)=<span class="cm-mu">[</span><span class="cm-known">sqrtest</span>(<span class="cm-mu">#</span>,a)<span class="cm-mu">]</span></span><br>
        </p>
        <p [innerHTML]="'MUEXAMPLECOMMENT' | translate"></p>

        <mat-divider></mat-divider>
        <p style="padding-top:15px">
          <b translate>MOREINCOMOSIM</b>:
        </p>
        <p [innerHTML]="'MOREPRFINCOMOSIMHELPER' | translate"></p>
        <p [innerHTML]="'MOREPRFINCOMOSIMCANTOR' | translate"></p>
        <ng-katex equation="\begin{aligned}enc(a_1,a_2,...,a_n) &= \langle a_1, a_2, ..., a_n\rangle \\dec_i(\langle a_1, a_2, ..., a_n\rangle) &= a_{i+1}\end{aligned}"></ng-katex>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div layout="row" style="display:flex;overflow:hidden;height:100%">
  <div style="flex:1;overflow:auto">
    <div style="display:flex">
      <mat-form-field style="margin:10px;flex:1;margin-bottom:-15px">
        <input matInput [(ngModel)]="func.currentComment" placeholder="{{'DESCRIPTION' | translate}}" (ngModelChange)="onChange(-1)" [disabled]="func.isBasic()">
      </mat-form-field>
      <span style="margin:5px;">
        <button mat-button mat-icon-button (click)="generate(-1)" matTooltip="{{ 'OPENPRFGENERATOR' | translate}}">
          <mat-icon>star_border</mat-icon>
        </button>
        <button mat-button mat-icon-button (click)="convertToLoop()" [disabled]="!func.currentPRF" matTooltip="{{ 'CONVERT2LOOP' | translate}}">
          <mat-icon>swap_calls</mat-icon>
        </button>
        <button mat-button mat-icon-button (click)="showJS()" [disabled]="!func.currentPRF" matTooltip="{{ 'JSCODE' | translate}}">
          <b>JS</b>
        </button>
        <button mat-raised-button color="primary" style="margin:5px;" (click)="save()" [disabled]="!func.canSave" matTooltip="{{ 'SAVEFUNCTION' | translate}}">
          <span translate>SAVE</span>
        </button>
      </span>
    </div>
    <div style="display:flex">
      <ngx-codemirror class="cmprf mono" #cm [(ngModel)]="func.currentCode" [options]="config" [autoFocus]="true" (ngModelChange)="onChange(-1)" [disabled]="func.isBasic()"></ngx-codemirror>
      <button mat-raised-button color="primary" class="openevalbutton" (click)="openEval(-1)" [disabled]="!func.currentPRF" matTooltip="{{ 'SHOWEVAL' | translate}}">
        <mat-icon>play_arrow</mat-icon>
      </button>
    </div>
    <div layout="row" style="display:flex;height:60px">
      <span id="infopanel" [class]="func.msgType" [innerHTML]="func.message | translate:func.messageParam"></span>
      <span id="rightalign">
        <button mat-raised-button color="secondary" (click)="addHelper(0)" [disabled]="func.isBasic()" style="margin-top:15px;margin-right:5px;" matTooltip="{{ 'HELPERBELOW' | translate}}">
          <span translate>HELPERFUNCTION</span>
          <mat-icon>subdirectory_arrow_left</mat-icon>
        </button>
      </span>
    </div>
    <div *ngFor="let h of func.currentHelper; let i = index" style="height:auto;margin:0">
      <mat-divider></mat-divider>
      <div layout="column" style="width:100%">
        <div style="display:flex">
          <mat-form-field style="margin:10px;flex:1;margin-bottom:-15px">
            <input matInput [(ngModel)]="h.currentComment" placeholder="{{'DESCRIPTION' | translate}}" (ngModelChange)="onChange(i)">
          </mat-form-field>
          <span id="rightalign" style="margin:5px;">
            <button mat-button mat-icon-button (click)="generate(i)" matTooltip="{{ 'OPENPRFGENERATOR' | translate}}">
              <mat-icon>star_border</mat-icon>
            </button>
            <button mat-button mat-icon-button style="margin:5px;" color="secondary" (click)="removeHelper(i)" matTooltip="{{ 'DELETEFUNC' | translate}}">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </span>
        </div>
        <div style="display:flex">
          <ngx-codemirror class="cmprf mono" #hcm [(ngModel)]="h.currentCode" [options]="config" [autoFocus]="true" (ngModelChange)="onChange(i)"></ngx-codemirror>
          <button mat-raised-button color="primary" class="openevalbutton" (click)="openEval(i)" [disabled]="!h.currentPRF" matTooltip="{{ 'SHOWEVAL' | translate}}">
            <mat-icon>play_arrow</mat-icon>
          </button>
        </div>
        <div layout="row" style="display:flex;height:60px">
          <p id="infopanel" [class]="h.msgType" [innerHTML]="h.message | translate:h.messageParam"></p>
          <span id="rightalign">
            <button mat-raised-button color="secondary" (click)="addHelper(i+1)" style="margin-top:15px;margin-right:5px;" matTooltip="{{ 'HELPERBELOW' | translate}}">
              <span translate>HELPERFUNCTION</span>
              <mat-icon>subdirectory_arrow_left</mat-icon>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
  <mat-divider [vertical]="true"></mat-divider>
  <prf-eval style="display:flex;flex-direction:column" *ngIf="showEval" [sampleprf]="sampleprf" canClose="true" (wantClose)="closeeval()"></prf-eval>
</div>


import { DatabaseService } from "../general/database.service";
import { Injectable } from "@angular/core";

export class LoopTokenizer {
    getFunctions: Function;
    ignore = "(),;:= \t";
    electricInput = /END/;
    constructor(getFns: Function) {
        this.getFunctions = getFns;
    }
    startState() {
        return {
            vars: {},
            f: this.getFunctions(),
            looplevel:0
        };
    }
    token(stream, state) {
        if (this.ignore.includes(stream.peek())) {
            stream.next();
            return null;
        }
        else {
            let v = stream.eatWhile(new RegExp("[^"+this.ignore+"]")) ? stream.string.substring(stream.start,stream.pos) : "";
            if (v == "LOOP" || v == "DO" || v == "END" || v == "WHILE") {
                if (v == "DO") state.looplevel += 4;
                if (v == "END") state.looplevel -= 4;
                return "help"
            }
            if (stream.peek() == "(") {
                let n = v.split("_")[0];
                if (state.f.find(func => func.name == n))
                    return "known"
                return "unknown";
            }
            if (v == "x0")
                return "var-red";
            let n = Number.parseInt(v);
            if (!isNaN(n))
                return "var-constant";
            if (!state.vars[v])
                state.vars[v] = "var-" + ((Object.keys(state.vars).length) % 6);
            return state.vars[v];
        }
    }
    indent(state, textAfter): number {
        if (textAfter.startsWith("END"))
            return state.looplevel - 4;
        return state.looplevel;
    }
}

class MyStringStream{
    string: string;
    pos: number;

    constructor(s:string) {
        this.pos = 0;
        this.string = s;
    }
    peek() { return this.string.charAt(this.pos) || undefined };
    next() {
        if (this.pos < this.string.length) { return this.string.charAt(this.pos++) }
    };
    eat(match) {
        var ch = this.string.charAt(this.pos);
        var ok;
        if (typeof match == "string") { ok = ch == match; }
        else { ok = ch && (match.test ? match.test(ch) : match(ch)); }
        if (ok) { ++this.pos; return ch }
    };
    eatWhile(match) {
        var start = this.pos;
        while (this.eat(match)) { }
        return this.pos > start
    }
}
@Injectable()
export class LoopFormattingService {
    public tokenizer: LoopTokenizer;
    constructor(private db: DatabaseService) {
        this.tokenizer = new LoopTokenizer(() => {
            return db.loops.reduce((l, f) => {
                l.push(f);
                return l;
            }, [])
        });
    }
    public colorizeFormula(s: string) {
        let state = this.tokenizer.startState();
        let result = "";
        for (let line of s.split("\n")) {
            if (result != "") result += "\n"
            let stream = new MyStringStream(line);
            let lastpos = 0;
            while (stream.pos < stream.string.length) {
                let t = this.tokenizer.token(stream, state);
                if (!t)
                    result += stream.string.substring(lastpos, stream.pos)
                else
                    result += "<span class='cm-" + t + "'>" + stream.string.substring(lastpos, stream.pos) + "</span>"
                lastpos = stream.pos;
            }
        }
        return result;
    }
}

<div id="homeview">
    <div class="homecontent">
        <mat-card class="maincard">
            <mat-card-header>
                <mat-card-title style="font-size:26px"><span [innerHTML]="'COMOSIMLONG'|translate"></span></mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content style="margin-top:15px">
                <p class="infotext" [innerHTML]="'INFO1'|translate"></p>
                <ng-katex equation="f:\N^n \mapsto \N, n \in \N"></ng-katex>
                <p class="infotext" [innerHTML]="'INFO2'|translate"></p>
                <p class="infotext" [innerHTML]="'INFO4'|translate"></p>
            </mat-card-content>
        </mat-card>
        <mat-card class="maincard">
            <mat-card-content style="margin-top:-10px;margin-bottom:-10px">
                <div style="padding-top:15px;padding-bottom:15px">
                    <button mat-raised-button color="primary" class="get-started" routerLink="/loop">{{'LOOP'|translate}}</button>
                    <button mat-raised-button color="primary" class="get-started" routerLink="/prf">{{'PRF'|translate}}</button>
                </div>
                <p *ngIf="!supportsBigInt" style="font-size:14px" class="infotext" [innerHTML]="'HINTBIGINT'|translate"></p>
            </mat-card-content>
        </mat-card>
        <mat-card class="maincard">
            <mat-card-content style="margin-top:-10px;margin-bottom:-10px">
                <p style="padding-top:15px;padding-bottom:15px;font-size:14px">
                    <span [innerHTML]="'INFO3'|translate"></span>
                </p>
            </mat-card-content>
        </mat-card>
        <mat-card class="maincard">
            <mat-card-header>
                <mat-card-title style="font-size:26px"><span [innerHTML]="'TUTORIALS'|translate"></span></mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content style="margin-top:15px;">
                <p>
                <video width="853" height="480" controls style="margin:5px">
                    <source src="CoMoSim-LOOP-WHILE.mp4" type="video/mp4" >
                    Your browser does not support the video tag.
                </video>
                </p>
                <p>
                <video width="853" height="480" controls style="margin:5px">
                    <source src="CoMoSim-PRF.mp4" type="video/mp4" >
                    Your browser does not support the video tag.
                </video>
                </p>
                <p>
                <video width="853" height="480" controls style="margin:5px">
                    <source src="CoMoSim-Mu-And-More.mp4" type="video/mp4" >
                    Your browser does not support the video tag.
                </video>
                </p>
            </mat-card-content>
        </mat-card>
    </div>
</div>

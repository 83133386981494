import { Injectable } from "@angular/core";
import { PRF, VARARGS, PRFType, Equation, Func, FuncMu } from "../prf/prf";
import { Loop, LoopType, LoopStatement, StmAssign, StmCall, StmLoop, StmWhile } from "../loop/loop";
import { DatabaseService } from "./database.service";

class LoopConvertState{
    code: string;
    nextVar: number;
    constructor(code: string, nextVar: number) {
        this.code = code;
        this.nextVar = nextVar;
    }
    getFreeVar():string {
        return "x" + this.nextVar++;
    }
}

class Num{
    n: number;
    constructor(n: number) { this.set(n); }
    next(): number { this.n++; return this.n - 1;}
    set(n: number) { this.n = n; }
}
class VariableMap{
    vars: Map<string, string>;
    nvar: Num;
    constructor(next: Num) {
        this.vars = new Map<string, string>();
        this.nvar = next;
    }
    set(k: string, v: string) {
        this.vars.set(k, v);
    }
    get(k: string) {
        let r = this.vars.get(k);
        if (!r) {
            r = "x" + this.nvar.next();
            this.vars.set(k, r);
        }
        return r;
    }
}

@Injectable()
export class TransformService {
    constructor(private db:DatabaseService) { }
    //***************************************************************************************
    // PRF zu LOOP
    private addPRF(cvt: LoopConvertState, resVar: string, f: PRF, i: number, param: Array<string>, prefix: string="") {
        switch (f.type) {
            case PRFType.CONS: cvt.code += prefix+resVar + ":=" + i; break;
            case PRFType.PROJ: cvt.code += prefix+resVar + ":= " + param[i - 1]; break;
            case PRFType.SUCC: cvt.code += prefix + resVar + ":=succ(" + param[0] + ")"; break;
            case PRFType.ENC: cvt.code += prefix + resVar + ":=enc(" + param.join(",") + ")"; break;
            case PRFType.DEC: cvt.code += prefix + resVar + ":=dec_"+i+"(" + param[0] + ")"; break;
            default: {
                if (f.definition.length > 1) { // Rekursion
                    let loopVar = cvt.getFreeVar();
                    cvt.code += prefix+loopVar + ":=0;\n";
                    let eCall = f.definition[0].rf;
                    this.addPRF(cvt, resVar, eCall.f, eCall.i as number, param.slice(1), prefix);
                    cvt.code += ";\n"
                    cvt.code += prefix + "LOOP "+param[0]+" DO\n";
                    let rCall = f.definition[1].rf;
                    this.addPRF(cvt, resVar, rCall.f, rCall.i as number, [loopVar, resVar].concat(param.slice(1)), prefix+"\t");
                    cvt.code += ";\n"
                    cvt.code += prefix+"\t"+loopVar + ":=succ("+loopVar+")\n";
                    cvt.code += prefix+"END";
                }
                else { // Substitution oder Mu-Rekursion
                    let call = f.definition[0].rf;
                    if (call instanceof (FuncMu)) {
                        cvt.code += prefix + resVar + ":=0;\n";                             // resVar := 0;
                        let r = cvt.getFreeVar();
                        let par = param.slice(0, call.mui);
                        par.push(resVar);
                        par = par.concat(param.slice(call.mui));
                        this.addPRF(cvt, r, call.f, call.i as number, par, prefix);         // r := f(..., resVar, ...)
                        cvt.code += ";\n"
                        cvt.code += prefix + "WHILE " + r + " DO\n";                        // WHILE r DO
                        cvt.code += prefix + "\t" + resVar + ":=succ(" + resVar + ");\n";   //  resVar := succ(resVar)
                        this.addPRF(cvt, r, call.f, call.i as number, par, prefix + "\t");  //  r := f(...,resVar,...)
                        cvt.code += "\n"
                        cvt.code += prefix + "END";                                         // END
                    }
                    else {
                        let subres: Array<string> = []
                        let sep = "";
                        for (let subfunc of call.params as Array<Func>) { // Zunächst die inneren Funktionen
                            let r = cvt.getFreeVar();
                            cvt.code += sep;
                            this.addPRF(cvt, r, subfunc.f, subfunc.i as number, param, prefix);
                            subres.push(r);
                            sep = ";\n"
                        }
                        cvt.code += sep;
                        this.addPRF(cvt, resVar, call.f, call.i as number, subres, prefix);
                    }
                }
            }
        }
        
    }
    public prf2loop(f: PRF): [string, number, string]{ // Liefert Name, Anzahl Parameter und LOOP code
        let par = f.param.filter(p => p !== VARARGS);
        let cvt = new LoopConvertState("", par.length + 1);
        this.addPRF(cvt, "x0", f, -1, par.map((p, i) => "x" + (i + 1)));
        console.log(cvt.code);
        return [f.name, par.length, cvt.code];
    }
    //***************************************************************************************
    // LOOP expandieren sodass nur Basisfunktionen verwendet werden
    private expandStatement(r: Array<LoopStatement>, s: LoopStatement, vars:VariableMap) {
        if (s instanceof StmAssign) { // einfache Zuweisungen direkt übernehmen
            let src = typeof (s.sourceVar) == "number" ? s.sourceVar : vars.get(s.sourceVar);
            r.push(new StmAssign(vars.get(s.targetVar), src));
        }
        else if (s instanceof StmWhile) { // Whiles übernehmen
            let l = new StmWhile(vars.get(s.loopVar), []);
            for (let d of s.statements) // Inhalt einzeln expandieren
                this.expandStatement(l.statements, d, vars);
            r.push(l);
        }
        else if (s instanceof StmLoop) { // Loops übernehmen
            let l = new StmLoop(vars.get(s.loopVar), []);
            for (let d of s.statements) // Inhalt einzeln expandieren
                this.expandStatement(l.statements, d, vars);
            r.push(l);
        }
        else if (s instanceof StmCall) // Funktionsaufrufe sind das einzige was wirklich expandiert werden muss
        {
            if (s.func.isBasic())
                r.push(new StmCall(vars.get(s.targetVar), s.func, s.param.map(p => typeof (p) == "number" ? p : vars.get(p)),s.i));
            else {
                let varMap = new VariableMap(vars.nvar) // neues Variablen-Mapping mit globalem Variablen-Counter
                let res = varMap.get("x0"); // Neue temporäre Ergebnisvariable zur Ersetzung von x0
                r.push(new StmAssign(res, 0)); // Initialisierung der Ergebnisvariablen mit 0
                let impVars = s.func.getImplicitVars(); // Implizite Variablen
                for (let i = 1; i <= s.param.length; i++) { // Argumente des Funktionsaufrufs
                    let v = varMap.get("x" + i); // Neue temporäre Eingabevariable zur Ersetzung von xi
                    let p = s.param[i - 1]; // Argument für xi
                    let src = typeof (p) == "number" ? p : vars.get(p); // Entweder Konstante oder Variable
                    r.push(new StmAssign(v, src)); // Zuweisung des Arguments zur neuen Variable
                    impVars.delete("x" + i); // Eingabevariablen sind implizit, werden aber explizit gesetzt
                }
                for (let iv of impVars) { // Übrige implizite Variablen
                    let v = varMap.get(iv); // Neue Ersatzvariablen erzeugen
                    r.push(new StmAssign(v, 0)); // Und explizit mit 0 initialisieren
                }
                let tvar = vars.get(s.targetVar);
                for (let d of s.func.statements)
                    this.expandStatement(r, d, varMap)
                r.push(new StmAssign(tvar, res)); // Übernahme des Funktionsergebnisses in Zielvariable
            }
        }
    }
    public expandLoopFunction(f: Loop):Loop {
        let r = new Loop(f.type, f.name, f.index, f.nParam, f.comment); // Expandierte Funktion
        r.statements = new Array<LoopStatement>(); // und deren neue Anweisungen
        let varMap = new VariableMap(new Num(f.nParam + 1)); // Variablen-Mapping
        varMap.set("x0", "x0"); // Auf oberster Ebene bleibt x0 als x0 erhalten
        for (let i = 1; i <= f.nParam; i++) // Die Argument-Variablen
            varMap.set("x" + i, "x" + i); // ebenso
        for (let d of f.statements) // Originale Anweisungen iterativ auflösen 
            this.expandStatement(r.statements, d, varMap);
        return r;
    }
    //***************************************************************************************
    // LOOP optimieren und unnütze Statements entfernen
    //  Was wird zur Berechnung von x0 benötigt (required = [x0])
    //  Von hinten nach vorn (nur Änderungen von required[] sind relevant)
    //  >>> Anweisungen die auftauchen und etwas ändern was nicht required ist können ignoriert werden
    //  Letzte Anweisung  ist (1) Zuweisung, (2) Loop oder (3) While
    //      (1) Wenn required[i] gesetzt wird -> Die Parameter der Zuweisung werden benötigt (required.add(xa,xb,xc))
    //      (2+3) Das innere des Blocks von hinten nach vorn (required.add(loop/while-var))
    private tagRequiredStatements(statements: LoopStatement[], required: Set<string>): boolean {
        let changed = false;
        for (let i = statements.length; i > 0; i--) { // Statements rückwärts
            let stm = statements[i - 1];
            if (stm instanceof StmAssign) { // Eine Zuweisung
                if (required.has(stm.targetVar)) { // Eine benötigte Variable wird zugewiesen
                    if (!stm.required) { // wenn das Statement nicht schon als notwendig markiert ist
                        stm.required = true; // markieren
                        changed = true; // Änderung signalisieren
                        if (typeof stm.sourceVar === "string") // Wird eine andere Variable zugewiesen?
                            required.add(stm.sourceVar); // Dann wird auch diese benötigt
                    }
                }
            }
            else if (stm instanceof StmCall) { // Ein Aufruf
                if (required.has(stm.targetVar)) { // Eine benötigte Variable wird zugewiesen
                    if (!stm.required) { // wenn das Statement nicht schon als notwendig markiert ist
                        stm.required = true; // markieren
                        changed = true; // Änderung signalisieren
                        for (let p of stm.param as Array<string>) // Als Argumente verwendete Variablen
                            required.add(p); // Werden ebenfalls benötigt
                    }
                }
            }
            else if (stm instanceof StmLoop) { // Ein Loop oder While Block
                while (this.tagRequiredStatements(stm.statements, required)) { // wenn darin benötigte Variblen zugeordnet werden
                    if (!stm.required) { // wenn der Block nicht schon als notwendig markiert ist
                        stm.required = true; // markieren
                        changed = true; // Änderung signalisieren
                        required.add(stm.loopVar); // die Loop-Variable wird benötigt
                    }
                }
            }
        }
        return changed;
    }
    private removeNotRequiredStatements(statements: LoopStatement[]): void {
        for (let i = statements.length; i > 0; i--) { // Statements rückwärts
            let stm = statements[i - 1];
            if (!stm.required)
                statements.splice(i-1, 1);
            else if (stm instanceof StmLoop) { // inkl. While
                this.removeNotRequiredStatements(stm.statements);
            }
        }
    }
    public optimizeLoop(loop: Loop): Loop{
        
        this.tagRequiredStatements(loop.statements, new Set<string>(["x0"]));
        this.removeNotRequiredStatements(loop.statements);
        return loop;
    }
    
    //***************************************************************************************
    // LOOP zu PRF
    private getInOut(s: LoopStatement, input: Set<string | number> = new Set(), output: Set<string> = new Set()): [Set<String | number>, Set<String | number>]{
        if (s instanceof StmAssign) {
            input.add(s.sourceVar);
            output.add(s.targetVar);
        }
        else if (s instanceof StmCall) {
            for (let p of s.param)
                input.add(p)
            output.add(s.targetVar);
        }
        else if (s instanceof StmLoop) {
            input.add(s.loopVar);
            for (let sub of s.statements) {
                this.getInOut(sub,input,output);
            }
        }
        return [input, output];
    }
    private createSubstitution(name:string, param:Array<string>, outer:PRF, outerIndex:string|number, inner:Array<Func>):PRF {
        let subPRF = new PRF(PRFType.HELP, name, undefined, param.slice(), "");
        subPRF.definition = [new Equation(new Func(subPRF, undefined, param.slice()), new Func(outer, outerIndex, inner))];
        return subPRF;
    }
    private createRecursion(name: string, outer: PRF, outerIndex: string | number): PRF {
        let recPRF = new PRF(PRFType.HELP, name, undefined, ["r","g"], "");
        recPRF.definition = [
            new Equation(
                new Func(recPRF, undefined, [new Func(this.db.cPRF,0,[]),"g"]),
                new Func(this.db.uPRF, 1, ["g"])
            ),
            new Equation(
                new Func(recPRF, undefined, [new Func(this.db.sPRF, undefined, ["r"]), "g"]),
                new Func(outer, outerIndex, ["r",new Func(recPRF,undefined,["r","g"]),"g"])
            )
        ];
        return recPRF;
    }
    private getStatementsPRF(statements: Array<LoopStatement>, prefix:string, nv:number, helper: Array<PRF>, globals: Map<Loop, PRF>):PRF {
        let currentPRF: PRF = undefined; // Substitution mit der Aufrufsequenz
        let nLoop = 1; // ID zur Numerierung der Aufrufe
        for (let s of statements) { // Die einzelnen Aufrufe
            let name = "";
            let subPRF = undefined;
            if (s instanceof (StmAssign)) { // Zuweisung einer Variablen oder Konstanten
                name = prefix + ".set" + nLoop;
                // xi := xj >> enc(dec_0(g),dec_1(g), dec_j(g), ..) an Stelle i dec_j(g)
                // xi:= c >> enc(dec_0(g),dec_1(g), C_c(g), ..) an Stelle i C_c(g)
                let i = Number.parseInt(s.targetVar.slice(1)); // i
                let source = new Func(this.db.cPRF, s.sourceVar, ["g"]); // xi:= c >> C_c(g)
                if (typeof (s.sourceVar) != "number") { // xi := xj
                    let j = Number.parseInt(s.sourceVar.slice(1)); // j
                    source = new Func(this.db.decPRF, j, ["g"]); // dec_j(g)
                }
                let param = []
                for (let k = 0; k < nv; k++) // Zielbelegung der Variablen
                    param.push(k == i ? source : new Func(this.db.decPRF, k, ["g"])); // dec_k(g) außer wenn k == i
                subPRF = this.createSubstitution(name, ["g"], this.db.encPRF, undefined, param);
            }
            else if (s instanceof (StmCall)) { // Aufruf einer externen LOOP-Funktion
                name = prefix + ".call" + nLoop;
                // xi := func(xj,c) >> enc(dec_0(g),dec_1(g), func(dec_j(g), C_c(g)), ... )
                let funcPRF = this.getLoopPRF(s.func, helper, globals); // PRF-Pendant der LOOP-Funktion ermitteln/erzeugen
                // Ermittlung der Eingangsvariablen 
                let decParam = s.param.map(p => { // für jedes Argument des LOOP Aufrufs
                    if (typeof (p) == "number") // ist es eine Konstante
                        return new Func(this.db.cPRF, p, ["g"]); // konstante PRF mit Index p
                    else // ist es eine Variable >> mittels dec aus g dekodieren
                        return new Func(this.db.decPRF, Number.parseInt(p.slice(1)), ["g"])
                })
                // call(g) = func(dec_j(g),C_c(g)) >> Aufruf der PRF mit den entsprechenden Variablen
                let callPRF = this.createSubstitution(name + "." + funcPRF.name, ["g"], funcPRF, s.i, decParam);
                helper.push(callPRF);
                let i = Number.parseInt(s.targetVar.slice(1)); // Zielindex
                let param = []
                for (let k = 0; k < nv; k++) // Zielbelegung der Variablen: am Zielindex callPRF(g), sonst dec_k(g)
                    param.push(k == i ? new Func(callPRF, undefined, ["g"]) : new Func(this.db.decPRF, k, ["g"]));
                subPRF = this.createSubstitution(name, ["g"], this.db.encPRF, undefined, param);
            }
            else if (s instanceof (StmWhile)) { // Ein WHILE Block
                name = prefix + ".while" + nLoop;
                // Die Statements innerhalb des Loop-Blocks stm(g)=...
                let loopContentPRF = this.getStatementsPRF(s.statements, name, nv, helper, globals);
                // werden als 3-stellige Funktion benötigt: stmsub(i,r,g)=stm(U_2(i,r,g))
                let subContentPRF = this.createSubstitution(name + ".sub", ["i", "r", "g"], loopContentPRF, undefined, [new Func(this.db.uPRF, 2, ["i", "r", "g"])]);
                helper.push(subContentPRF);
                // Die rekursive Ausführung der Statements rec(S(r),g)=stm(rec())
                let loopRec = this.createRecursion(name + ".rec", subContentPRF, undefined);
                helper.push(loopRec);
                let i = Number.parseInt(s.loopVar.slice(1)); // Loop-Var-Index
                let getsub = this.createSubstitution(name + ".var", ["m", "g"], this.db.decPRF, i, [new Func(loopRec, undefined, ["m", "g"])]);
                helper.push(getsub);

                let muPRF = new PRF(PRFType.HELP, name+".mu", undefined, ["g"], "");
                muPRF.definition = [new Equation(new Func(muPRF, undefined, ["g"]), new FuncMu(getsub, undefined, ["#","g"]))];
                helper.push(muPRF);

                // Ziel: xxx(g)=loopRec(s.loopVar,g) bzw. = loopRec(dec_loopvarindex(g),U_1(g))
                subPRF = this.createSubstitution(name, ["g"], loopRec, undefined, [new Func(muPRF, undefined, ["g"]), new Func(this.db.uPRF, 1, ["g"])]);
            }
            else if (s instanceof (StmLoop)) { // Ein LOOP Block
                name = prefix + ".loop" + nLoop;
                // Die Statements innerhalb des Loop-Blocks stm(g)=...
                let loopContentPRF = this.getStatementsPRF(s.statements, name, nv, helper, globals);
                // werden als 3-stellige Funktion benötigt: stmsub(i,r,g)=stm(U_2(i,r,g))
                let subContentPRF = this.createSubstitution(name + ".sub", ["i", "r", "g"], loopContentPRF, undefined, [new Func(this.db.uPRF, 2, ["i", "r", "g"])]);
                helper.push(subContentPRF);
                // Die rekursive Ausführung der Statements rec(S(r),g)=stm(rec())
                let loopRec = this.createRecursion(name + ".rec", subContentPRF, undefined);
                helper.push(loopRec);
                // Ziel: xxx(g)=loopRec(s.loopVar,g) bzw. = loopRec(dec_loopvarindex(g),U_1(g))
                let i = Number.parseInt(s.loopVar.slice(1)); // Zielindex
                subPRF = this.createSubstitution(name, ["g"], loopRec, undefined, [new Func(this.db.decPRF,i,["g"]), new Func(this.db.uPRF,1,["g"])]);
            }
            // g.nLoop.NameLoopFunktion(g)=enc(dec_0(g), ...)
            helper.push(subPRF); // Als Subfunktion merken
            if (!currentPRF) // Es ist die erste Funktion der Aufrufsequenz
                currentPRF = subPRF; // als aktuellen Gesamtaufruf übernehmen
            else { // Es ist ein Folgeaufruf
                // g.nLoop.NameLoopFunktion(g)=aktuellerAufruf(vorherigeAufrufe(g))
                let newLoopPRF = this.createSubstitution("seq" + nLoop++ + "." + prefix, ["g"], subPRF, undefined, [new Func(currentPRF, undefined, ["g"])]);
                helper.push(newLoopPRF);
                currentPRF = newLoopPRF; // Wird zum Gesamtaufruf
            }
        }
        return currentPRF;
    }
    private getLoopPRF(f: Loop, helper: Array<PRF>, globals: Map<Loop, PRF>): PRF{
        let loopPRF = globals.get(f); // Gibt es bereits eine PRF zur Loop-Funktion?
        if (loopPRF) return loopPRF; // Dann diese direkt nehmen
        // Anzahl der in der LOOP Funktion verwendeten Variablem (max i der xi, +1 für x0)
        let nv = Array.from(f.getUsedVars()).reduce((max, cur) => Math.max(max, Number.parseInt(cur.slice(1))), 0) + 1;
        let currentPRF: PRF = this.getStatementsPRF(f.statements, f.name, nv, helper, globals); // Substitution mit der Aufrufsequenz
        let currentPRFIndex = undefined;
        if (!currentPRF) { // Es handelt sich um die leere Loop-Funktion
            currentPRF = this.db.uPRF; // Repräsentiert durch unveränderte Projektion der Eingabegödelnummer auf die Ausgabe
            currentPRFIndex = 1; // Gödelnummer ist das erste und einzige Argument der Funktion die die Aufufsequenz repräsentiert
        }
        // currentPRF(g)=LetzerAufruf(vorherigeAufrufe(g)) >> außer bei leerer Loop-Funktion: currentPRF == U_1
        // Ziel: LoopPRF(a,b,..) = dec_0( currentPRF( enc(C_0(a,b,...),U_1(a,b,...),... ) )
        let mainParam = this.getArray("a", f.nParam, 1); // Die Parameterliste der äquivalenten LoopPRF: [a,b,...]
        // Zunächst: encode(a,b,..) = enc(C_0(a,b,...),U_1(a,b,...),... )
        let encodeParam = [];
        for (let i = 0; i < nv; i++) {
            if (i == 0)
                encodeParam.push(new Func(this.db.cPRF, 0, mainParam.slice()));
            else if (i <= f.nParam)
                encodeParam.push(new Func(this.db.uPRF, i, mainParam.slice()));
            else
                encodeParam.push(new Func(this.db.cPRF, 0, mainParam.slice()));
        }
        let encodePRF = this.createSubstitution("enc." + f.name, mainParam, this.db.encPRF, undefined, encodeParam);
        helper.push(encodePRF);
        // Dann: call.fname(a,b,..) = currentPRF( encodePRF(a,b,...) )
        let callPRF = this.createSubstitution("call.enc." + f.name, mainParam, currentPRF, currentPRFIndex, [new Func(encodePRF, undefined, mainParam.slice())]);
        helper.push(callPRF);
        // Zuletzt: LoopPRF(a,b,..) = dec_0( g.res.fname(a,b,..) ) >> äquivalent zur LOOP-Funktion
        loopPRF = this.createSubstitution(f.name, mainParam, this.db.decPRF, 0, [new Func(callPRF, undefined, mainParam.slice())]);
        helper.push(loopPRF);
        globals.set(f, loopPRF); // Die äquivalente Funktion zur Wiederverwendung hinzufügen
        return loopPRF;
    }
    private getArray(prefix: string, n: number, offset: number = 0):Array<string> {
        return Array.from(new Array(n), (val, index) => prefix + (index + offset));
    }
    public loop2prf(f: Loop): PRF {
        let helper = []; // lokale Hilfsfunktionen
        let globals = new Map<Loop, PRF>([
            [this.db.sLoop, this.db.sPRF],
            [this.db.encLoop, this.db.encPRF],
            [this.db.decLoop, this.db.decPRF]
        ]); // wiederverwendbare Entsprechungen
        let loopPRF = this.getLoopPRF(f, helper, globals);
        loopPRF.helper = helper.slice(0, -1).reverse();
        for (let i = loopPRF.helper.length; i > 0; i--)
            loopPRF.helper[i - 1].name = "h" + i;
        loopPRF.type = PRFType.NEW;
        return loopPRF;
    }
    
}